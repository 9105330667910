import { Space } from "antd";
import { DeleteIcon } from "../Icons/DeleteIcon";
import { EditIcon } from "../Icons/EditIcon";
import { ViewIcon } from "../Icons/ViewIcon";

const TableAction = ({
  view = false,
  edit = false,
  deleteU = false,
  onView,
  onEdit,
  onDelete,
  traceData = false,
}) => {
  return (
    <Space>
      {view && (
        <span
          className="cursor-pointer hover:text-[blue]"
          title="View"
          onClick={onView}
        >
          <ViewIcon />
        </span>
      )}
      {traceData && (
        <>
          {" "}
          {edit && (
            <span onClick={onEdit} title="Edit">
              <EditIcon className="cursor-pointer hover:text-[blue]" />
            </span>
          )}
          {deleteU && (
            <span onClick={onDelete} title="Delete">
              <DeleteIcon className="cursor-pointer hover:text-[red]" />
            </span>
          )}
        </>
      )}
      
    </Space>
  );
};

export default TableAction;
