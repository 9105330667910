import React from "react";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  let { token } = useParams();
  
  return <div>dadhahgdagh {token}</div>;
};

export default ResetPassword;
