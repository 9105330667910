export const subscribedData = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Last 2 days",
    value: "last_2_days",
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "last 30 days",
    value: "last_30_days",
  },
];

export const statusData = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "InActive",
    value: "in-active",
  },
];

export const PlanStatus = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Premium",
    value: "premium",
  },
  {
    label: "Trial",
    value: "trial",
  },
  {
    label: "Free",
    value: "free",
  },
];

export const graphOptions = [
  {
    value: 3,
    label: "Last 3 Months",
  },
  {
    value: 6,
    label: "Last 6 Months",
  },
  {
    value: 9,
    label: "Last 9 Months",
  },
  {
    value: 12,
    label: "Last 12 Months",
  },
];

export const camelCase = (text) => {
  try {
    if (!text) {
      return ""; // or throw an error, depending on your use case
    }

    const words = text.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  } catch (error) {
    // Handle the error appropriately
    return ""; // or throw an error
  }
};

export const failedProductTime = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Last Week",
    value: "lastWeek",
  },
  {
    label: "Last Month",
    value: "lastMonth",
  },
  {
    label: "last 3 Months",
    value: "last3Months",
  },
];

export const failedProductStatus = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Nutrition Score",
    value: "nutrition_present",
  },
  {
    label: "Ingredients Score",
    value: "ingredients_present",
  },
  {
    label: "Sustainability Score",
    value: "sustainability_present",
  },
];
