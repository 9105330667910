import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withAuth = (Component) => {
  // every page must be binded with withAuth in order to protect it.
  // this component validate user if user is authenticated then it will navigate to desire page
  // else it will redirect to login page
  const Auth = (props) => {
    const router = useNavigate();
    // const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const token = localStorage.getItem("token");
    const isAuthenticated = token ? true : false;

    useEffect(() => {
      // this code snipet will check is user authenticated or not
      if (!isAuthenticated) {
        router("/login");
      }
         // eslint-disable-next-line
    }, []);

    // return desire component if user is authenticated
    return <Component {...props} />;
  };
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }
  return Auth;
};

export default withAuth;
