import React, { createContext, useContext, useState } from "react";
import Loader from "./Loader"; // Import your Loader component

const LoaderContext = createContext(undefined);

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const showLoader = () => {
    setLoading(true);
  };

  const hideLoader = () => {
    setLoading(false);
  };

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader, loading }}>
      {children}
      {loading && <Loader />}
    </LoaderContext.Provider>
  );
};
