import React from "react";

const DownIcon = ({ fill, className, onClick, width, height }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        width={width || "20"}
        height={height || "11"}
        viewBox="0 0 20 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1.125L10 9.875L19 1.125"
          stroke={fill || "black"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DownIcon;
