export const matchLabelColor = (text) => {
  return (
    (text === "very_strong_match" && "#EAF5E5") ||
    (text === "strong_match" && "#E4FFD8") ||
    (text === "moderate_match" && "#FFF3D2") ||
    (text === "weak_match" && "#FFE2E0") ||
    (text === "no_match" && "#E7E7E7") ||
    (text === "no_preference" && "#E7E7E7")
  );
};
export const matchTextColor = (text) => {
  return (
    (text === "very_strong_match" && "#006747") ||
    (text === "strong_match" && "#70B850") ||
    (text === "moderate_match" && "#CD9B17") ||
    (text === "weak_match" && "#F9572B") ||
    (text === "no_match" && "#828282")
  );
};

export const getColorCode = (scoreValue) => {
  let numericScore = parseFloat(scoreValue);
  if (isNaN(numericScore)) {
    console.error("scoreValue is not a valid number:", scoreValue);
    return null;
  }

  let roundedScore;

  if (Number.isInteger(numericScore)) {
    roundedScore = numericScore;
  } else {
    roundedScore = parseFloat(numericScore.toFixed(1).replace(/\.0$/, ""));
  }

  const colorMap = {
    0: "#FF3615",
    0.1: "#FF3917",
    0.2: "#FF3C19",
    0.3: "#FF3F1B",
    0.4: "#FF421D",
    0.5: "#FF441F",
    0.6: "#FF4721",
    0.7: "#FF4A23",
    0.8: "#FF4D25",
    0.9: "#FF5027",
    1: "#F9582C",
    1.1: "#F95A2C",
    1.2: "#F95C2D",
    1.3: "#F95E2D",
    1.4: "#F9602D",
    1.5: "#FA622E",
    1.6: "#FA652E",
    1.7: "#FA672E",
    1.8: "#FA692E",
    1.9: "#FA6B2F",
    2: "#FA6D2F",
    2.1: "#FA6F2F",
    2.2: "#FA7130",
    2.3: "#FA7430",
    2.4: "#FA7631",
    2.5: "#FB7831",
    2.6: "#FB7A31",
    2.7: "#FB7C32",
    2.8: "#FB7F32",
    2.9: "#FB8133",
    3: "#FB8333",
    3.1: "#FB8533",
    3.2: "#FB8734",
    3.3: "#FB8934",
    3.4: "#FB8B35",
    3.5: "#FB8E35",
    3.6: "#FB9035",
    3.7: "#FB9236",
    3.8: "#FB9436",
    3.9: "#FB9637",
    4: "#FB9837",
    4.1: "#FB9A37",
    4.2: "#FB9C38",
    4.3: "#FB9F38",
    4.4: "#FBA139",
    4.5: "#FBA339",
    4.6: "#FBA539",
    4.7: "#FBA73A",
    4.8: "#FBAA3A",
    4.9: "#FBAC3B",
    5: "#FBAE3B",
    5.1: "#F8AF3C",
    5.2: "#F5B13C",
    5.3: "#F3B23D",
    5.4: "#F0B43D",
    5.5: "#EDB53E",
    5.6: "#EAB63F",
    5.7: "#E7B83F",
    5.8: "#E5B940",
    5.9: "#E2BB40",
    6: "#DFBC41",
    6.1: "#D9BC41",
    6.2: "#D3BC42",
    6.3: "#CDBC42",
    6.4: "#C7BC42",
    6.5: "#C2BC42",
    6.6: "#BCBC43",
    6.7: "#B6BC43",
    6.8: "#B0BC43",
    6.9: "#AABC44",
    7: "#A4BC44",
    7.1: "#9EBB44",
    7.2: "#98BA45",
    7.3: "#91B945",
    7.4: "#8BB845",
    7.5: "#85B846",
    7.6: "#7FB746",
    7.7: "#79B646",
    7.8: "#72B546",
    7.9: "#6CB447",
    8: "#66B347",
    8.1: "#61AF47",
    8.2: "#5DAC47",
    8.3: "#58A848",
    8.4: "#54A548",
    8.5: "#4FA148",
    8.6: "#4A9D48",
    8.7: "#469A48",
    8.8: "#419649",
    8.9: "#3D9349",
    9: "#388F49",
    9.1: "#338B49",
    9.2: "#2F8849",
    9.3: "#2A8449",
    9.4: "#268149",
    9.5: "#227D4A",
    9.6: "#1D794A",
    9.7: "#19764A",
    9.8: "#14724A",
    9.9: "#106F4A",
    10: "#0B6B4A",
  };

  return colorMap[roundedScore];
};

export const colorMap = {
  0: "#FF3615",
  0.1: "#FF3917",
  0.2: "#FF3C19",
  0.3: "#FF3F1B",
  0.4: "#FF421D",
  0.5: "#FF441F",
  0.6: "#FF4721",
  0.7: "#FF4A23",
  0.8: "#FF4D25",
  0.9: "#FF5027",
  1: "#F9582C",
  1.1: "#F95A2C",
  1.2: "#F95C2D",
  1.3: "#F95E2D",
  1.4: "#F9602D",
  1.5: "#FA622E",
  1.6: "#FA652E",
  1.7: "#FA672E",
  1.8: "#FA692E",
  1.9: "#FA6B2F",
  2: "#FA6D2F",
  2.1: "#FA6F2F",
  2.2: "#FA7130",
  2.3: "#FA7430",
  2.4: "#FA7631",
  2.5: "#FB7831",
  2.6: "#FB7A31",
  2.7: "#FB7C32",
  2.8: "#FB7F32",
  2.9: "#FB8133",
  3: "#FB8333",
  3.1: "#FB8533",
  3.2: "#FB8734",
  3.3: "#FB8934",
  3.4: "#FB8B35",
  3.5: "#FB8E35",
  3.6: "#FB9035",
  3.7: "#FB9236",
  3.8: "#FB9436",
  3.9: "#FB9637",
  4: "#FB9837",
  4.1: "#FB9A37",
  4.2: "#FB9C38",
  4.3: "#FB9F38",
  4.4: "#FBA139",
  4.5: "#FBA339",
  4.6: "#FBA539",
  4.7: "#FBA73A",
  4.8: "#FBAA3A",
  4.9: "#FBAC3B",
  5: "#FBAE3B",
  5.1: "#F8AF3C",
  5.2: "#F5B13C",
  5.3: "#F3B23D",
  5.4: "#F0B43D",
  5.5: "#EDB53E",
  5.6: "#EAB63F",
  5.7: "#E7B83F",
  5.8: "#E5B940",
  5.9: "#E2BB40",
  6: "#DFBC41",
  6.1: "#D9BC41",
  6.2: "#D3BC42",
  6.3: "#CDBC42",
  6.4: "#C7BC42",
  6.5: "#C2BC42",
  6.6: "#BCBC43",
  6.7: "#B6BC43",
  6.8: "#B0BC43",
  6.9: "#AABC44",
  7: "#A4BC44",
  7.1: "#9EBB44",
  7.2: "#98BA45",
  7.3: "#91B945",
  7.4: "#8BB845",
  7.5: "#85B846",
  7.6: "#7FB746",
  7.7: "#79B646",
  7.8: "#72B546",
  7.9: "#6CB447",
  8: "#66B347",
  8.1: "#61AF47",
  8.2: "#5DAC47",
  8.3: "#58A848",
  8.4: "#54A548",
  8.5: "#4FA148",
  8.6: "#4A9D48",
  8.7: "#469A48",
  8.8: "#419649",
  8.9: "#3D9349",
  9: "#388F49",
  9.1: "#338B49",
  9.2: "#2F8849",
  9.3: "#2A8449",
  9.4: "#268149",
  9.5: "#227D4A",
  9.6: "#1D794A",
  9.7: "#19764A",
  9.8: "#14724A",
  9.9: "#106F4A",
  10: "#0B6B4A",
};

export const RangeColorCode = [
  { score: 0, colorCode: "#FF3615" },
  { score: 0.1, colorCode: "#FF3917" },
  { score: 0.2, colorCode: "#FF3C19" },
  { score: 0.3, colorCode: "#FF3F1B" },
  { score: 0.4, colorCode: "#FF421D" },
  { score: 0.5, colorCode: "#FF441F" },
  { score: 0.6, colorCode: "#FF4721" },
  { score: 0.7, colorCode: "#FF4A23" },
  { score: 0.8, colorCode: "#FF4D25" },
  { score: 0.9, colorCode: "#FF5027" },
  { score: 1, colorCode: "#F9582C" },
  { score: 1.1, colorCode: "#F95A2C" },
  { score: 1.2, colorCode: "#F95C2D" },
  { score: 1.3, colorCode: "#F95E2D" },
  { score: 1.4, colorCode: "#F9602D" },
  { score: 1.5, colorCode: "#FA622E" },
  { score: 1.6, colorCode: "#FA652E" },
  { score: 1.7, colorCode: "#FA672E" },
  { score: 1.8, colorCode: "#FA692E" },
  { score: 1.9, colorCode: "#FA6B2F" },
  { score: 2, colorCode: "#FA6D2F" },
  { score: 2.1, colorCode: "#FA6F2F" },
  { score: 2.2, colorCode: "#FA7130" },
  { score: 2.3, colorCode: "#FA7430" },
  { score: 2.4, colorCode: "#FA7631" },
  { score: 2.5, colorCode: "#FB7831" },
  { score: 2.6, colorCode: "#FB7A31" },
  { score: 2.7, colorCode: "#FB7C32" },
  { score: 2.8, colorCode: "#FB7F32" },
  { score: 2.9, colorCode: "#FB8133" },
  { score: 3, colorCode: "#FB8333" },
  { score: 3.1, colorCode: "#FB8533" },
  { score: 3.2, colorCode: "#FB8734" },
  { score: 3.3, colorCode: "#FB8934" },
  { score: 3.4, colorCode: "#FB8B35" },
  { score: 3.5, colorCode: "#FB8E35" },
  { score: 3.6, colorCode: "#FB9035" },
  { score: 3.7, colorCode: "#FB9236" },
  { score: 3.8, colorCode: "#FB9436" },
  { score: 3.9, colorCode: "#FB9637" },
  { score: 4, colorCode: "#FB9837" },
  { score: 4.1, colorCode: "#FB9A37" },
  { score: 4.2, colorCode: "#FB9C38" },
  { score: 4.3, colorCode: "#FB9F38" },
  { score: 4.4, colorCode: "#FBA139" },
  { score: 4.5, colorCode: "#FBA339" },
  { score: 4.6, colorCode: "#FBA539" },
  { score: 4.7, colorCode: "#FBA73A" },
  { score: 4.8, colorCode: "#FBAA3A" },
  { score: 4.9, colorCode: "#FBAC3B" },
  { score: 5, colorCode: "#FBAE3B" },
  { score: 5.1, colorCode: "#F8AF3C" },
  { score: 5.2, colorCode: "#F5B13C" },
  { score: 5.3, colorCode: "#F3B23D" },
  { score: 5.4, colorCode: "#F0B43D" },
  { score: 5.5, colorCode: "#EDB53E" },
  { score: 5.6, colorCode: "#EAB63F" },
  { score: 5.7, colorCode: "#E7B83F" },
  { score: 5.8, colorCode: "#E5B940" },
  { score: 5.9, colorCode: "#E2BB40" },
  { score: 6, colorCode: "#DFBC41" },
  { score: 6.1, colorCode: "#D9BC41" },
  { score: 6.2, colorCode: "#D3BC42" },
  { score: 6.3, colorCode: "#CDBC42" },
  { score: 6.4, colorCode: "#C7BC42" },
  { score: 6.5, colorCode: "#C2BC42" },
  { score: 6.6, colorCode: "#BCBC43" },
  { score: 6.7, colorCode: "#B6BC43" },
  { score: 6.8, colorCode: "#B0BC43" },
  { score: 6.9, colorCode: "#AABC44" },
  { score: 7, colorCode: "#A4BC44" },
  { score: 7.1, colorCode: "#9EBB44" },
  { score: 7.2, colorCode: "#98BA45" },
  { score: 7.3, colorCode: "#91B945" },
  { score: 7.4, colorCode: "#8BB845" },
  { score: 7.5, colorCode: "#85B846" },
  { score: 7.6, colorCode: "#7FB746" },
  { score: 7.7, colorCode: "#79B646" },
  { score: 7.8, colorCode: "#72B546" },
  { score: 7.9, colorCode: "#6CB447" },
  { score: 8, colorCode: "#66B347" },
  { score: 8.1, colorCode: "#61AF47" },
  { score: 8.2, colorCode: "#5DAC47" },
  { score: 8.3, colorCode: "#58A848" },
  { score: 8.4, colorCode: "#54A548" },
  { score: 8.5, colorCode: "#4FA148" },
  { score: 8.6, colorCode: "#4A9D48" },
  { score: 8.7, colorCode: "#469A48" },
  { score: 8.8, colorCode: "#419649" },
  { score: 8.9, colorCode: "#3D9349" },
  { score: 9, colorCode: "#388F49" },
  { score: 9.1, colorCode: "#338B49" },
  { score: 9.2, colorCode: "#2F8849" },
  { score: 9.3, colorCode: "#2A8449" },
  { score: 9.4, colorCode: "#268149" },
  { score: 9.5, colorCode: "#227D4A" },
  { score: 9.6, colorCode: "#1D794A" },
  { score: 9.7, colorCode: "#19764A" },
  { score: 9.8, colorCode: "#14724A" },
  { score: 9.9, colorCode: "#106F4A" },
  { score: 10, colorCode: "#0B6B4A" },
];

// template-1

export const dietaryFiberScoreT1 = {
  score: 5,
  score_code: "H2",
  benefit: true,
  concern: false,
  score_text: " Moderate",
  quantity_details: " 1.25 g",
  itag_template: "template-1",
  more_information: "",
  reference_url:
    "Ofcom Nutrient Model: https://www.ndph.ox.ac.uk/food-ncd/files/about/uk-ofcom-nutrient-profile-model.pdf , Nutriscore: https://world.openfoodfacts.org/nutriscore",
  more_info_visual: {
    start_data: 0,
    start_better_range: 4.7,
    end_better_range: 9,
    end_data: 9,
  },
  value: 5,
  unit: "g",
};

// template-2

export const dietaryFiberScoreT2 = {
  score: 5,
  score_code: "H2",
  benefit: true,
  concern: false,
  score_text: " Moderate",
  quantity_details: " 1.25 g",
  itag_template: "template-2",
  more_information:
    "Calories from Protein=32.0 | Calories from fat=648.0 | Calories from",
  reference_url:
    "Ofcom Nutrient Model: https://www.ndph.ox.ac.uk/food-ncd/files/about/uk-ofcom-nutrient-profile-model.pdf , Nutriscore: https://world.openfoodfacts.org/nutriscore",
  more_info_visual: {
    start_data: 0,
    start_better_range: 4.7,
    end_better_range: 9,
    end_data: 9,
  },
  value: 5,
  unit: "g",
};

// template-3

export const dietaryFiberScoreT3 = {
  score: 5,
  score_code: "H2",
  benefit: true,
  concern: false,
  score_text: " Moderate",
  quantity_details: " 1.25 g",
  itag_template: "template-2",
  more_information: [
    "Calcium=23.08mg",
    "Phosphorus=0.02mg",
    "Potassium=8.72mg",
    "Riboflavin=30.77mg",
    "Sodium=5.87mg",
    "Vitamin A=10.0mcg",
    "Vitamin B12=50.0mcg",
    "Vitamin D=12.5mcg",
    "",
  ],
  reference_url:
    "Ofcom Nutrient Model: https://www.ndph.ox.ac.uk/food-ncd/files/about/uk-ofcom-nutrient-profile-model.pdf , Nutriscore: https://world.openfoodfacts.org/nutriscore",
  more_info_visual: {
    start_data: 0,
    start_better_range: 4.7,
    end_better_range: 9,
    end_data: 9,
  },
  value: 5,
  unit: "g",
};

// template-4

export const dietaryFiberScoreT4 = {
  score: 5,
  score_code: "H2",
  benefit: true,
  concern: false,
  score_text: " Moderate",
  quantity_details: " 1.25 g",
  itag_template: "template-2",
  more_information: `'Additives = "number"; preservatives = "number". |Growing scientific evidence suggests that synthetic food additives may adversely affect health. Despite allergic reactions in some, ongoing debates question food preservatives' safety. More long-term studies are needed for accurate risk assessment`,
  reference_url:
    "Ofcom Nutrient Model: https://www.ndph.ox.ac.uk/food-ncd/files/about/uk-ofcom-nutrient-profile-model.pdf , Nutriscore: https://world.openfoodfacts.org/nutriscore",
  more_info_visual: {
    start_data: 0,
    start_better_range: 4.7,
    end_better_range: 9,
    end_data: 9,
  },
  value: 5,
  unit: "g",
};

export function roundDownToOneDecimalPlace(number) {
  return Math.floor(number * 10) / 10;
}

export const dummyData = [
  {
    product_details: {
      title:
        "RiceSelect Sushi Rice for Sushi and Japanese Food, Premium Gluten-Free Short-Grain Rice, Non-GMO, 32-Ounce Jar",
      subTitle: "",
      score: 8.26925,
      rating: 4.7,
      total_number_of_ratings: 4.7,
      reviews_summary: "",
      keywords_list: [],
      product_id: 1,
      match: "no_match",
    },
    scoring_information: [
      {
        nutrition: {
          subfields: [
            {
              dietary_fiber: {
                score: 5,
                score_code: "H2",
                benefit: true,
                concern: false,
                score_text: " Moderate",
                quantity_details: " 1.25 g",
                itag_template: "template-1",
                more_information: "",
                reference_url:
                  "Ofcom Nutrient Model: https://www.ndph.ox.ac.uk/food-ncd/files/about/uk-ofcom-nutrient-profile-model.pdf , Nutriscore: https://world.openfoodfacts.org/nutriscore",
                more_info_visual: {
                  start_data: 0,
                  start_better_range: 4.7,
                  end_better_range: 9,
                  end_data: 9,
                },
                value: 5,
                unit: "g",
              },
            },
            {
              transfat: {
                score: 10,
                score_code: "H12",
                benefit: true,
                concern: false,
                score_text: " Very low",
                quantity_details: " 0 g",
                itag_template: "",
                more_information:
                  'Trans fats raise "bad" cholesterol and lower "good" cholesterol, boosting heart disease risk. The U.S. FDA has banned the primary source of artificial trans fats in foods. Ref: Mayoclinic [https://www.mayoclinic.org/diseases-conditions/high-blood-cholesterol/in-depth/trans-fat/art-20046114], WHO [https://www.who.int/news-room/fact-sheets/detail/trans-fat#:~:text=Trans%20fat%20clogs%20arteries%2C%20increasing,as%20crackers%2C%20biscuits%20and%20pies.]',
                reference_url:
                  "Mayoclinic : https://www.mayoclinic.org/diseases-conditions/high-blood-cholesterol/in-depth/trans-fat/art-20046114, WHO : https://www.who.int/news-room/fact-sheets/detail/trans-fat#:~:text=Trans%20fat%20clogs%20arteries%2C%20increasing,as%20crackers%2C%20biscuits%20and%20pies.",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
          ],
          score: 8,
          score_text: "Good nutrition quality",
          per_serving: 25,
          more_information: "",
        },
      },
      {
        ingredients__and__processing: {
          subfields: [
            {
              "non-gmo_project_verified": {
                score: 10,
                score_code: "H15Q21",
                benefit: true,
                concern: false,
                score_text: " Follows the Non-GMO Project standards",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "A nonprofit organization, ensures your right to know what's in your food by administering North America's strictest GMO-free certification.Ref: Non-GMO Project [https://www.nongmoproject.org/]",
                reference_url:
                  "Non-GMO Project : https://www.nongmoproject.org/",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              "gluten-free": {
                score: 8,
                score_code: "H26A1",
                benefit: true,
                concern: false,
                score_text: " Does not contain gluten and its derivates",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "This is for your reference and not scored. Gluten-free products are safe for wheat-allergic individuals. Ref:Acaai[https://acaai.org/allergies/allergic-conditions/food/wheat-gluten/]",
                reference_url:
                  "Acaai : https://acaai.org/allergies/allergic-conditions/food/wheat-gluten/",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              whole_grain_council: {
                score: 8,
                score_code: "H15Q22",
                benefit: true,
                concern: false,
                score_text: " Indicates total whole grain content",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "The Whole Grains Council helps consumers find & understand the health benefits of whole grain foods with three stamp types: Basic, 50%, and 100%. Ref: Wholegrainscouncil [https://wholegrainscouncil.org/whole-grain-stamp]",
                reference_url:
                  "Wholegrainscouncil : https://wholegrainscouncil.org/whole-grain-stamp",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              "%_of_organic": {
                score: 6,
                score_code: "H16",
                benefit: true,
                concern: false,
                score_text: " 70% Certified Organic",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Contains minimum of 70% organic ingredients, without an organic logo on the package. Ref:USDA [https://www.ams.usda.gov/rules-regulations/organic/labeling]",
                reference_url:
                  "USDA : https://www.ams.usda.gov/rules-regulations/organic/labeling",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              "natural_&_no_gmos": {
                score: 10,
                score_code: "H22",
                benefit: true,
                concern: false,
                score_text: " Yes",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Does not contain GM ingredients. A genetically modified organism (GMO) is an organism created through biotechnology, combining genes from various sources like plants, animals, bacteria, and viruses, not found in nature or through traditional breeding. Ref: Non-GMO Project [https://www.nongmoproject.org/gmo-facts/]",
                reference_url:
                  "Non-GMO Project : https://www.nongmoproject.org/gmo-facts/",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              vegetarian: {
                score: 8,
                score_code: "H19",
                benefit: true,
                concern: false,
                score_text: " Contains plant-based ingredients and dairy",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "A well-planned vegetarian diet meets nutritional needs healthily, while excessive red and processed meat consumption raises health risks. Ref: Mayoclinic [https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/vegetarian-diet/art-20046446]",
                reference_url:
                  "Mayoclinic : https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/vegetarian-diet/art-20046446",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              mediterranean_diet: {
                score: 8,
                score_code: "H27",
                benefit: true,
                concern: false,
                score_text:
                  " Focuses more on the plant-based diet with traditional flavors & regional cooking methods.",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Mediterranean diet promotes heart health, weight management, and longevity through emphasis on plant-based foods and healthy fats.\nRef: https://www.hsph.harvard.edu/nutritionsource/healthy-weight/diet-reviews/mediterranean-diet/,https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/mediterranean-diet/art-20047801",
                reference_url:
                  "Harvard: https://www.hsph.harvard.edu/nutritionsource/healthy-weight/diet-reviews/mediterranean-diet/, Mayoclinic: https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/mediterranean-diet/art-20047801",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              milk: {
                score: 6,
                score_code: "H26A5",
                benefit: false,
                concern: true,
                score_text: " May contain milk and its derivatives",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Individuals allergic to milk should avoid products containing milk or its derivatives to prevent allergic reactions.\nRef: Mayoclinic [https://www.mayoclinic.org/diseases-conditions/milk-allergy/symptoms-causes/syc-20375101], FDA [https://www.fda.gov/food/buy-store-serve-safe-food/food-allergies-what-you-need-know]\n",
                reference_url:
                  "Mayoclinic: https://www.mayoclinic.org/diseases-conditions/milk-allergy/symptoms-causes/syc-20375101], FDA: https://www.fda.gov/food/buy-store-serve-safe-food/food-allergies-what-you-need-know",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              soy: {
                score: 6,
                score_code: "H26A12",
                benefit: false,
                concern: true,
                score_text: " May contain soy and its derivatives",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Soy-allergic individuals should avoid products with soy or its derivatives to prevent allergic reactions.\nRef: ACAAI [https://acaai.org/allergies/allergic-conditions/food/soy/], FDA [https://www.fda.gov/food/buy-store-serve-safe-food/food-allergies-what-you-need-know]",
                reference_url:
                  "ACAAI: https://acaai.org/allergies/allergic-conditions/food/soy/, FDA: https://www.fda.gov/food/buy-store-serve-safe-food/food-allergies-what-you-need-know",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              vegan: {
                score: 1,
                score_code: "H20",
                benefit: false,
                concern: true,
                score_text: " May include animal-derived ingredients",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "A diet high in red meat and processed meats has been linked to an increased risk of various health diseases. Ref: WHO [https://iris.who.int/bitstream/handle/10665/370775/9789240074828-eng.pdf?sequence=1]",
                reference_url:
                  "HHP : https://www.health.harvard.edu/blog/with-a-little-planning-vegan-diets-can-be-a-healthful-choice-2020020618766",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
          ],
          score: 8.8,
          score_text: "Very good",
          more_information: "",
        },
      },
      {
        sustainability: {
          subfields: [
            {
              soil: {
                score: 8,
                score_code: "S1",
                benefit: true,
                concern: false,
                score_text: " Minimal loss enhances soil fertility levels",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "With minimal use of pesticides and fertilizers, good Soil Biodiversity supports strong plant growth and enhances ecosystem adaptability. Ref:FAO[https://www.fao.org/resources/digital-reports/soil-biodiversity/en/]",
                reference_url:
                  "NIFA : https://www.nifa.usda.gov/topics/organic-agriculture",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              carbon: {
                score: 8,
                score_code: "S4",
                benefit: true,
                concern: false,
                score_text: " Emits <90% than Avg. red meat",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Eq. to driving a car between 6 to 17 miles. Plant-based foods require less energy, land, and water, with lower carbon emissions compared to animal-based foods. Ref: UNO [https://www.un.org/en/climatechange/science/climate-issues/food]",
                reference_url:
                  "UNO : https://www.un.org/en/climatechange/science/climate-issues/food",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              fair_for_life: {
                score: 10,
                score_code: "S2L2",
                benefit: true,
                concern: false,
                score_text: " Ensures ethical",
                quantity_details: " fair trade sourcing.",
                itag_template: "",
                more_information:
                  "Fair for Life ensures fair trade practices and social responsibility across supply chains, promoting ethical sourcing. Ref: Fair for life[https://www.fairforlife.org/pmws/indexDOM.php?client_id=fairforlife&page_id=about&lang_iso639=en]",
                reference_url:
                  "Fair for life : https://www.fairforlife.org/pmws/indexDOM.php?client_id=fairforlife&page_id=about&lang_iso639=en",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              "co-ops": {
                score: 8,
                score_code: "S2L4",
                benefit: true,
                concern: false,
                score_text: " Promotes social",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "US cooperatives advocate sustainability via sustainable practices, local sourcing, social responsibility, member education, and advocacy.",
                reference_url: "-",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              peta_approved: {
                score: 10,
                score_code: "S3L12",
                benefit: true,
                concern: false,
                score_text:
                  " Certifies cruelty-free products without animal testing.",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Certified by People for the Ethical Treatment of Animals (PETA) as cruelty-free, indicating that no animal testing was conducted during the production .Ref:PETA[https://www.petaindia.com/features/peta-vegan-logo/#:~:text=The%20%E2%80%9CPETA%2DApproved%20Vegan%E2%80%9D,%2C%20wool%2C%20fur%20and%20feathers.]",
                reference_url:
                  "PETA : https://www.petaindia.com/features/peta-vegan-logo/#:~:text=The%20%E2%80%9CPETA%2DApproved%20Vegan%E2%80%9D,%2C%20wool%2C%20fur%20and%20feathers.",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              food_alliance_label: {
                score: 10,
                score_code: "S3L45",
                benefit: true,
                concern: false,
                score_text: " Addresses safe and fair working conditions",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Ensures farms, ranches, and food processors and distributors follow sustainable agricultural and facility management practices. Ref: Food Alliance [https://foodalliance.org/]",
                reference_url: "Food Alliance : https://foodalliance.org/",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              "recycled_claim_standard_[rcs-100]": {
                score: 9,
                score_code: "S5P2",
                benefit: true,
                concern: false,
                score_text:
                  " Contains 95-100% of recycled content of the packaging",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Verifies products with mixed recycled materials for supply chain transparency and integrity. Ref:Textile Exchange[https://textileexchange.org/recycled-claim-global-recycled-standard/]",
                reference_url:
                  "Textile Exchange: https://textileexchange.org/recycled-claim-global-recycled-standard/",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              certified_glyphosate_residue_free: {
                score: 9,
                score_code: "S3L16",
                benefit: true,
                concern: false,
                score_text: " Assures no herbicide traces in products",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Indicates that the product has been verified by a certification program to contain no detectable residues of glyphosate, a common herbicide.Ref:Detox Project [https://detoxproject.org/certification/glyphosate-residue-free/]",
                reference_url:
                  "Detox Project : https://detoxproject.org/certification/glyphosate-residue-free/",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              wfcf_organic: {
                score: 9,
                score_code: "S3L34",
                benefit: true,
                concern: false,
                score_text:
                  " Makes organic certification accessible and affordable",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Aims to make organic certification accessible to farmers, retailers and consumer packaged goods companies, ensuring it remains cost-effective.Ref: WFCF [https://www.wfcforganic.com/]",
                reference_url: " WFCF : https://www.wfcforganic.com/",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              recyclable: {
                score: 8,
                score_code: "S5P5",
                benefit: true,
                concern: false,
                score_text: " The packaging material can be recycled",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "Recycling cuts landfill waste, saves resources, and lowers pollution by reducing new raw material use. Ref: EPA [https://www.epa.gov/smm/recycling-economic-information-rei-report#:~:text=Recycling%20also%20conserves%20resources%20and,to%20collect%20new%20raw%20materials.]",
                reference_url:
                  "EPA : https://www.epa.gov/smm/recycling-economic-information-rei-report#:~:text=Recycling%20also%20conserves%20resources%20and,to%20collect%20new%20raw%20materials.",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
            {
              reusable: {
                score: 8,
                score_code: "S5P6",
                benefit: true,
                concern: false,
                score_text: " Reduce solid waste and CO2 emissions",
                quantity_details: "",
                itag_template: "",
                more_information:
                  "An eco-friendly alternative to single-use packaging",
                reference_url: "-",
                more_info_visual: {
                  start_data: "",
                  start_better_range: "",
                  end_better_range: "",
                  end_data: "",
                },
                value: "",
                unit: "",
              },
            },
          ],
          sustainable_score: 8.258333333333333,
          score_text: "Very good",
        },
      },
    ],
    message:
      "The provided scoring information does not specify details about Nishiki Premium Sushi Rice. However, if you are looking for general information about sushi rice, it typically has a sticky texture that holds together well, making it ideal for sushi. If you have specific questions about the nutritional quality, ingredients, sustainability, or any certifications related to Nishiki Premium Sushi Rice, please let me know, and I can provide information based on general knowledge or further research.",
    display_scoring_information: true,
    result_id: "664f09c5f7f7686a1ba01a26",
    question: "Nishiki Premium Sushi Rice",
    better_alternatives: [
      {
        product_id: 7,
        product_name:
          "EXPLORE CUISINE Organic Red Lentil Penne - High Protein, Gluten Free Pasta, Easy to Make - Organic, Non GMO - 24 Total Servings, 8 Ounce (Pack of 6)",
        image: "https://m.media-amazon.com/images/I/810Jk5KPF5L.jpg",
        brand_name: "EXPLORE CUISINE",
        match: "no_match",
        score: 3.063333333333333,
      },
      {
        product_id: 6,
        product_name: "Jovial Pasta Brown rice Organic Fusilli, 12 oz",
        image: "https://m.media-amazon.com/images/I/81JEFvW7FfL.jpg",
        brand_name: "Jovial",
        match: "no_match",
        score: 3.03,
      },
      {
        product_id: 8,
        product_name:
          "NOW Foods, Organic Quinoa Penne, Gluten-Free, Corn-Free, Non-GMO, Quinoa and Rice Pasta, 8-Ounce",
        image: "https://m.media-amazon.com/images/I/717rLaCfhyL.jpg",
        brand_name: "NOW Foods",
        match: "no_match",
        score: 2.9299999999999997,
      },
    ],
    is_liked: true,
  },
];

export const gradientColorsStart = [
  "#FF3615",
  "#F9582C",
  "#FA6D2F",
  "#FB8333",
  "#FB9837",
  "#FBAE3B",
  "#A4BC44",
];

export const gradientColorsMiddle = [
  "#0B6B4A",
  "#388F49",
  "#66B347",
  "#A4BC44",
  "#DFBC41",
];

export const gradientColorsEnd = [
  "#DFBC41",
  "#FB9837",
  "#FB8333",
  "#FA6D2F",
  "#F9582C",
  "#FF3615",
];

export const sanitizeProductName = (name) => {
  return name && name.replace(/[^a-zA-Z0-9 ]/g, " ");
};

export const calculateDeduction = (
  sections,
  isLeft,
  sectionVisibility,
  checkboxes
) => {
  let deduction = 0;

  sections.forEach((section, index) => {
    if (isLeft[index]) {
      deduction += 0.33; // Remove 33% if isLeft is true
    } else if (sectionVisibility[index]) {
      const sectionName = Object.keys(section)[0];

      if (
        sectionName === "ingredients_and_processing" ||
        sectionName === "sustainability"
      ) {
        const subfields = Object.values(section)[0]?.subfields || [];

        subfields.forEach((subfield, subIndex) => {
          if (checkboxes[`${index}-${subIndex}`]) {
            const scoreCode = subfield.score_code;
            let percentage = 0;

            // Define the fixed percentages for each score code
            if (sectionName === "ingredients_and_processing") {
              const ingredientProcessingScores = {
                H13: 20,
                H14: 20,
                H15: 10,
                H16: 30,
                H22: 5,
                H20: 5,
                H19: 5,
                H27: 5,
              };
              percentage = ingredientProcessingScores[scoreCode] || 0;
            } else if (sectionName === "sustainability") {
              const sustainabilityScores = {
                S1: 30,
                S2L2: 10,
                S2L4: 10,
                S3L12: 10,
                S3L45: 10,
                S3L16: 10,
                S3L34: 10,
                S4: 40,
                S5P2: 10,
                S5P5: 10,
                S5P6: 10,
              };
              percentage = sustainabilityScores[scoreCode] || 0;
            }
            deduction += percentage / 100;
          }
        });
      }
    }
  });

  return deduction;
};

export const iTag = [
  {
    key: "nutrition",
    itags: "Analyzes nutrients with OFCOM & Guiding Stars models.",
    urls: "Ofcom Nutrient Model: https://www.ndph.ox.ac.uk/food-ncd/files/about/uk-ofcom-nutrient-profile-model.pdf |Guiding stars: https://guidingstars.com/what-is-guiding-stars/",
  },
  {
    key: "ingredients__and__processing",
    itags: "Considers additives, processing, organic, non-gmo & plant-based.",
    urls: "FAO/CODEX:https://www.fao.org/gsfaonline/additives/index.html?lang=en|FDA:https://www.fda.gov/food/food-ingredients-packaging",
  },
  {
    key: "sustainability",
    itags: "Considers CO2e emissions, soil, recyclability & certificates.",
    urls: "bed42a1f12f3/content|UNO:https://wedocs.unep.org/bitstream/handle/20.500.11822/34463/JSUNEPPF.pdf?sequence=13&isAllowed=y",
  },
];
