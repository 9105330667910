import React from "react";
import { Spin, Modal } from "antd";

const Loader = () => {
  return (
    <Modal
      zIndex={99999999}
      open={true}
      closable={false}
      footer={false}
      centered={true}
      wrapClassName="loader"
    >
      <Spin size="large" />
    </Modal>
  );
};

export default Loader;
