import { Tooltip } from "antd";

export default function CutOffText({ value, maxLength, className }) {
  const stringValue = String(value);

  if (stringValue.length <= maxLength) {
    return <span className={className}>{stringValue}</span>;
  }

  return (
    <Tooltip title={stringValue}>
      <span className={className}>
        {stringValue.length > maxLength
          ? stringValue.substring(0, maxLength - 3) + "..."
          : stringValue}
      </span>
    </Tooltip>
  );
}
