import { Modal } from "antd";
import React from "react";
const CommonModal = ({
  title,
  isOpen,
  onCancel,
  onOk,
  children,
  footer,
  centered,
  closable,
  maskClosable,
  className,
  width,
  ...props
}) => {
  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      footer={footer}
      centered={centered}
      closable={closable}
      maskClosable={maskClosable}
      className={className}
      width={width}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CommonModal;
