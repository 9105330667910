import { Divider, Input, Typography } from "antd";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { emailSendValidationSchema } from "../../utils/validation";
import { useNavigate } from "react-router-dom";
import { forgotPasswordAsync } from "../../apis/authentication";

const ForgotPasswordForm = () => {
  const btnRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialSchema = {
    email: "",
  };

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialSchema,
    validationSchema: emailSendValidationSchema,
    onSubmit: async (values) => {
      handleForgotPassword();
    },
  });

  const handleNextRef = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "email") btnRef.current.focus();
    }
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      const response = await forgotPasswordAsync(values.email);
      if (!response.status) throw new Error(response.message);
      toast.success(response.message);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="flex-col w-full xl:w-7/12 gap-3 p-5">
      <ToastContainer limit={1} autoClose={1000} />
      <Typography className="text-4xl font-bold text-center mb-8">
        Forgot Password
      </Typography>

      {/* form */}
      <div className="flex gap-1 flex-col items-center">
        <div className="w-full">
          <Typography className="text-md font-semibold mb-2">Email</Typography>
          <Input
            type="text"
            name="email"
            placeholder="Email"
            value={values.email}
            status={errors.email && "error"}
            onChange={handleChange}
            onBlur={handleBlur}
            className="border-2 border-gray-300 p-3 text-lg"
            onKeyDown={handleNextRef}
          />
          <p className="text-red-400 text-xs font-semibold">{errors.email}</p>
        </div>

        <button
          disabled={loading}
          ref={btnRef}
          type="submit"
          onClick={handleSubmit}
          className="mt-2 bg-blue-500 disabled:bg-blue-200 hover:bg-blue-700 shadow-xl text-white p-3 text-lg rounded-md w-full"
        >
          Submit
        </button>
      </div>

      {/* back action  */}
      <div className="flex-col">
        <Divider className="border-[1px] bg-gray-400/40" />
        <Typography
          className="text-blue-500 hover:cursor-pointer hover:text-blue-700 text-center"
          onClick={() => navigate("/login")}
        >
          Back
        </Typography>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
