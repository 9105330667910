import { Button, Input, Select, Space, Typography } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  addPlanPricing,
  editPlanPricing,
  getPlanPricing,
  removePlanPricing,
} from "../apis/user";
import CommonTable from "../components/common/CommonTable";
import CutOffText from "../components/common/CutOffText";
import { useLoader } from "../components/common/Loader/LoaderProvider";
import ActionsModal from "../components/common/Modals/ActionsModal";
import CommonModal from "../components/common/Modals/CommonModal";
import TableAction from "../components/common/TableAction";
import CustomLayout from "../components/layout/CustomLayout";
import withAuth from "../routeProtector/withAuth";
import { planPricingValidationSchema } from "../utils/validation";
const { Option } = Select;

const Price = () => {
  const [userData, setUserData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [deleteUser, setDeleteUser] = useState(null);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editData, setEditData] = useState(null);
  const loaderContext = useLoader();
  const { showLoader, hideLoader } = loaderContext;

  const countryCurrencyData = [
    {
      country: "India",
      currency_name: "Indian Rupee",
      currency_code: "INR",
    },
    {
      country: "United States",
      currency_name: "US Dollar",
      currency_code: "USD",
    },
  ];

  const fetchUserData = async () => {
    showLoader();
    getPlanPricing()
      .then((res) => {
        if (res.status) {
          console.log("res", res);
          setUserData(res.data.data);
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .finally(() => {
        hideLoader();
      });
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) =>
        (a.country && b.country && a.country.localeCompare(b.country)) || 0,
      render: (text) => <>{<CutOffText maxLength={20} value={text} />}</>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) =>
        (a.currency && b.currency && a.currency.localeCompare(b.currency)) || 0,
    },
    {
      title: "Monthly Price",
      dataIndex: "monthly_price",
      key: "monthly_price",
    },
    {
      title: "Yearly Price",
      dataIndex: "yearly_price",
      key: "yearly_price",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <TableAction
            traceData={true}
            edit={true}
            onEdit={() => handleEdit(record)}
            deleteU={true}
            onDelete={() => handleDelete(record)}
          />
        </Space>
      ),
    },
  ];

  const handleDelete = async (record) => {
    setDeleteUser(record);
    setDeleteUserModal(true);
  };

  const handleEdit = async (record) => {
    setEditData(record);
    setIsEditMode(true);
    setIsModalVisible(true);
  };

  const handleDisableUser = async () => {
    if (!deleteUser) return;
    const data = {
      country: deleteUser.country,
    };
    try {
      const response = await removePlanPricing(data);
      console.log("response", response);
      if (response.status) {
        setDeleteUser(null);
        setDeleteUserModal(false);
        fetchUserData();
      } else {
        setDeleteUser(null);
        setDeleteUserModal(false);
      }
    } catch (err) {
      console.log(err);
      setDeleteUser(null);
      setDeleteUserModal(false);
    }
  };

  const handleAddPrice = () => {
    setIsModalVisible(true);
    setEditData(null);
    setIsEditMode(false);
  };
  console.log("editData", editData);
  const initialValues = {
    country: isEditMode ? editData.country : "",
    currency: isEditMode ? editData.currency : "",
    monthly_price: isEditMode ? editData.monthly_price : "",
    yearly_price: isEditMode ? editData.yearly_price : "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log("submit", values);
      const editValues = {
        country: values?.country,
        monthly_price: values?.monthly_price,
        yearly_price: values?.yearly_price,
      };
      const api = isEditMode
        ? editPlanPricing(editValues)
        : addPlanPricing(values);
      const res = await api;
      console.log("res", res);
      if (res.status) {
        toast.success(res.data.data.message);
        setSubmitting(false);
        resetForm();
        setIsModalVisible(false);
        fetchUserData();
      } else {
        toast.error(res.message);
        setSubmitting(false);
        resetForm();
        setIsModalVisible(false);
      }
    } catch (error) {
      setSubmitting(false);
      resetForm();
      setIsModalVisible(false);
    }
  };

  const handleCountryChange = (value) => {
    const selectedCountry = countryCurrencyData.find(
      (item) => item.country === value
    );
    setSelectedCurrency(selectedCountry ? selectedCountry.currency_code : "");
  };

  return (
    <CustomLayout>
      <ToastContainer limit={1} autoClose={1000} />
      <div className="flex justify-between items-center mb-4">
        <Typography.Text className="text-xl font-semibold capitalize">
          Premium Plan Price
        </Typography.Text>

        <Button type="primary" onClick={handleAddPrice}>
          Add Plan Price
        </Button>
      </div>
      <CommonTable
        apiData={userData && userData}
        columns={columns}
        pagination={false}
        search={false}
      />

      <CommonModal
        title={isEditMode ? "Edit Price..." : "Add Price..."}
        isOpen={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setIsEditMode(false);
          setEditData(null);
        }}
        footer={false}
        centered
        closable
        maskClosable={false}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={planPricingValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="mt-5">
                <div className="assignNutritionist flex items-center mb-4 justify-between">
                  <label className="whitespace-nowrap flex items-center font-poppins">
                    Country
                  </label>
                  <Field
                    name="country"
                    // className="assignInput mt-3 block border border-[#D7D8E4] bg-[#F6F7FF] w-full px-4 py-2 md:mt-0"
                  >
                    {({ field }) => (
                      <Select
                        className="w-full"
                        size="large"
                        disabled={isEditMode}
                        {...field}
                        onChange={(value) => {
                          setFieldValue("country", value);
                          handleCountryChange(value);
                          setFieldValue(
                            "currency",
                            countryCurrencyData.find(
                              (item) => item.country === value
                            )?.currency_code || ""
                          );
                        }}
                      >
                        <Option values="" selected>
                          Select Country
                        </Option>
                        {countryCurrencyData.map((item) => (
                          <Option key={item.country} value={item.country}>
                            {item.country}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-red-600"
                  />
                </div>
                <div className="assignNutritionist flex items-center mb-4 justify-between">
                  <label className="whitespace-nowrap flex items-center font-poppins">
                    Currency
                  </label>

                  <Field
                    name="currency"
                    className="assignInput mt-3 block border border-[#D7D8E4] bg-[#F6F7FF] w-full px-4 py-2 md:mt-0"
                  >
                    {({ field }) => (
                      <Select
                        {...field}
                        disabled
                        className="w-full"
                        size="large"
                      >
                        {selectedCurrency && (
                          <Option value={selectedCurrency}>
                            {
                              countryCurrencyData.find(
                                (item) =>
                                  item.currency_code === selectedCurrency
                              )?.currency_name
                            }
                          </Option>
                        )}
                      </Select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="currency"
                    component="div"
                    className="text-red-600"
                  />
                </div>
                <div className="assignNutritionist flex items-center mb-4 justify-between">
                  <label className="whitespace-nowrap flex items-center font-poppins">
                    Monthly Price
                  </label>
                  <Field
                    type="text"
                    name="monthly_price"
                    as={Input}
                    className="assignInput mt-3 block border !border-[#D7D8E4] !bg-[#F6F7FF] w-full px-4 py-2 md:mt-0"
                  />
                  <ErrorMessage
                    name="monthly_price"
                    component="div"
                    className="text-red-600"
                  />
                </div>
                <div className="assignNutritionist flex items-center mb-4 justify-between">
                  <label className="whitespace-nowrap flex items-center font-poppins">
                    Yearly Price
                  </label>
                  <Field
                    type="text"
                    name="yearly_price"
                    as={Input}
                    className="assignInput mt-3 block border border-[#D7D8E4] bg-[#F6F7FF] w-full px-4 py-2 md:mt-0"
                  />
                  <ErrorMessage
                    name="yearly_price"
                    component="div"
                    className="text-red-600"
                  />
                </div>

                <div className="flex gap-2 mt-7 justify-end">
                  <Button
                    type="primary"
                    className="custom-btn-form-primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </CommonModal>

      {deleteUserModal && (
        <ActionsModal
          title=""
          type="delete"
          isOpen={deleteUserModal}
          onCancel={() => setDeleteUserModal(false)}
          onOk={handleDisableUser}
          footer={false}
          centered={true}
          closable={false}
          maskClosable={false}
          className="delete-modal"
          cancelBtnClass="cancleBtnAction"
          cancelBtnClick={() => setDeleteUserModal(false)}
          cancelButtonProps="Cancel"
          saveBtnClass="saveBtnAction"
          saveBtnClick={handleDisableUser}
          saveButtonProps="Delete"
        />
      )}
    </CustomLayout>
  );
};

export default withAuth(Price);
