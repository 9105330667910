import React, { useEffect, useState } from "react";
import { getUsersSubscription } from "../apis/user";
import CommonTable from "../components/common/CommonTable";
import CutOffText from "../components/common/CutOffText";
import { useLoader } from "../components/common/Loader/LoaderProvider";
import CustomLayout from "../components/layout/CustomLayout";
import withAuth from "../routeProtector/withAuth";
import { PlanStatus } from "../utils/constant";

const Subscription = () => {
  const [userData, setUserData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [subscribed, setSubscribed] = useState("all" || null);
  const loaderContext = useLoader();
  const { showLoader, hideLoader } = loaderContext;
  const data = {
    page: currentPage,
    limit: limit,
    searchQuery: searchInput.trim(),
    status: subscribed,
  };

  const fetchUserData = async () => {
    showLoader();
    getUsersSubscription(data)
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          console.log("res", res);
          setUserData(res.data.data);
          setLimit(res.data.limit);
          setCurrentPage(res.data.page);
          setTotalItems(res.data.total);
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .finally(() => {
        hideLoader();
      });
  };
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [limit, currentPage, searchInput, subscribed]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name && b.name && a.name.localeCompare(b.name)) || 0,
      render: (text) => <>{<CutOffText maxLength={20} value={text} />}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) =>
        (a.email && b.email && a.email.localeCompare(b.email)) || 0,
    },
    {
      title: "Plan Type",
      dataIndex: "type",
      key: "type",
      render: (text) => (
        <span className="capitalize">{!text ? "-" : text}</span>
      ),
    },
    {
      title: "Upcoming Billing Date",
      dataIndex: "next_billing_date",
      key: "next_billing_date",
      render: (text, contact) => <span>{text}</span>,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setLimit(size);
  };

  return (
    <CustomLayout>
      <>
        <CommonTable
          apiData={userData && userData}
          columns={columns}
          currentPage={currentPage}
          itemsPerPage={limit}
          onPageChange={handlePageChange}
          onShowSizeChange={handleSizeChange}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          totalItems={totalItems}
          filter={true}
          showCSV={false}
          additionalFilters={[
            {
              defaultValue: subscribed,
              value: subscribed,
              placeholder: "Select Subscription",
              onChange: (value) => setSubscribed(value),
              options: PlanStatus,
            },
          ]}
        />
      </>
    </CustomLayout>
  );
};

export default withAuth(Subscription);
