import { CaretDownOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import {
  gradientColorsEnd,
  gradientColorsMiddle,
  gradientColorsStart,
} from "../../utils/searchPrompt";

const GradientRange = ({
  currentValue,
  startValue,
  startBetterValue,
  endBetterValue,
  endValue,
  unit,
}) => {
  const calculatePosition = (value) => {
    const range = endValue - startValue;
    return ((value - startValue) / range) * 100;
  };

  const [positions, setPositions] = useState({
    currentPosition: calculatePosition(currentValue),
    startBetterPosition: calculatePosition(startBetterValue),
    endBetterPosition: calculatePosition(endBetterValue),
  });

  useEffect(() => {
    setPositions({
      currentPosition: calculatePosition(currentValue),
      startBetterPosition: calculatePosition(startBetterValue),
      endBetterPosition: calculatePosition(endBetterValue),
    });
    // eslint-disable-next-line
  }, [currentValue, startValue, startBetterValue, endBetterValue, endValue]);

  const createGradientStops = (
    colors,
    startOffset,
    endOffset,
    addGap = false
  ) => {
    const numColors = colors.length;
    const stops = [];
    colors.forEach((color, index) => {
      const offset =
        startOffset + (index / (numColors - 1)) * (endOffset - startOffset);
      stops.push(
        <stop
          key={`${color}-${index}`}
          offset={`${offset}%`}
          style={{ stopColor: color, stopOpacity: 1 }}
        />
      );
    });
    if (addGap) {
      stops.push(
        <stop
          key={`gap-start-${endOffset}`}
          offset={`${endOffset}%`}
          style={{ stopColor: "white", stopOpacity: 1 }}
        />
      );
      stops.push(
        <stop
          key={`gap-end-${endOffset + 0.5}`}
          offset={`${endOffset + 0.5}%`}
          style={{ stopColor: "white", stopOpacity: 1 }}
        />
      );
    }
    return stops;
  };

  const gradientStopsStart = useMemo(
    () =>
      createGradientStops(
        gradientColorsStart,
        0,
        positions.startBetterPosition,
        true
      ),
    [positions.startBetterPosition]
  );

  const gradientStopsMiddle = useMemo(
    () =>
      createGradientStops(
        gradientColorsMiddle,
        positions.startBetterPosition,
        positions.endBetterPosition,
        true
      ),
    [positions.startBetterPosition, positions.endBetterPosition]
  );

  const gradientStopsEnd = useMemo(
    () =>
      createGradientStops(gradientColorsEnd, positions.endBetterPosition, 100),
    [positions.endBetterPosition]
  );

  const gradientId = `gradient-${currentValue}-${startValue}-${startBetterValue}-${endBetterValue}-${endValue}`;

  return (
    <div className="h-[4.5rem] flex relative mt-12 ">
      <div className="gradient-range">
        {currentValue < endValue ? (
          <div
            className="current-value flex flex-col justify-end "
            style={{ left: `${positions.currentPosition}%` }}
          >
            <span className="mt-2">{`${currentValue}${unit}`}</span>
            <CaretDownOutlined className="text-[#70B850] " />
          </div>
        ) : null}

        <svg width="100%" height="9.34px" className=" mt-0">
          <defs>
            <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
              {gradientStopsStart}
              {gradientStopsMiddle}
              {gradientStopsEnd}
            </linearGradient>
          </defs>
          <rect width="100%" height="20" fill={`url(#${gradientId})`} />
        </svg>
        <div className="labels">
          <span className="font-[600] text-[12px] leading-[15.78px]">
            {startValue}
            {unit}
          </span>
          <span className="flex items-center justify-center ">
            <span className="font-[600] text-[12px] leading-[14.4px] text-[#09694A]">
              Better Range :
            </span>
            <span className="font-[600] ml-1 text-[12px] leading-[14.4px] mt-[0.7px] text-[#09694A] ">
              {startBetterValue}-{endBetterValue}
            </span>
          </span>
          <span className="font-[600] text-[12px] leading-[15.78px]">
            {endValue}
            {unit}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GradientRange;
