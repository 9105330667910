import { Typography } from "antd";
import React from "react";
import { iTag } from "../../utils/searchPrompt";
const Template1 = ({ value }) => {
  // Extract the key from the value prop
  const key = Object.keys(value)[0];

  // Find the matching entry in the iTag array
  const match = iTag.find((item) => item.key === key);
  const itags = match?.itags ? match.itags.split(",") : [];

  // Split the URLs if they exist
  // const urls = match?.urls ? match.urls.split("|") : [];
  const references =
    match?.urls &&
    match?.urls?.split("|")?.map((ref) => {
      const colonIndex = ref.indexOf(":");
      if (colonIndex !== -1) {
        const label = ref?.substring(0, colonIndex)?.trim();
        const url = ref?.substring(colonIndex + 1)?.trim();
        return { label, url };
      } else {
        return { label: "", url: ref?.trim() };
      }
    });
  return (
    <div className="border px-[13px] py-[10px]">
      <div className="w-[232.63px]">
        <div className="flex flex-col items-center gap-4">
          <div className="capitalize flex justify-center items-start text-[#2F2F2F] font-[700] text-[18px] leading-[15.78px]">
             {/* eslint-disable-next-line */}
            {key == "ingredients__and__processing"
              ? "Ingredients"
              : key?.replace(/_/g, " ")}
          </div>
          {/* <Image src={infoModals} width={100} height={100} /> */}
          <ul className="list-disc pl-4">
            {itags.map((tag, index) => (
              <li
                key={index}
                className="text-[#1D1D1D] font-[400] text-[14px] leading-[17px]"
              >
                {tag.trim()}
              </li>
            ))}
          </ul>
          <div className="border-t-2 w-full h-[2px]"></div>
          <div className="flex flex-row items-start w-full">
            <div>
              <Typography.Text className="text-[#1D1D1D] font-[700] text-[14px] leading-[16.8px] capitalize">
                Ref:
              </Typography.Text>
            </div>
            <div className="text-start">
              {references?.map((ref, index) => (
                <div key={index} className={`ml-[4px]`}>
                  <div className="font-[500] text-[14px] leading-[15.78px] mt-1">
                    <div
                      // href={ref?.url}
                      // target="popup"
                      // rel="noopener noreferrer"
                      // onClick={() => {
                      //   window.open(
                      //     `${ref?.url}`,
                      //     "popup",
                      //     "width=600,height=600,margin=auto,scrollbars=yes"
                      //   );
                      //   return false;
                      // }}
                      className="text-[#2F2F2F] font-[500] text-[14px] leading-[18.41px] capitalize"
                    >
                      {ref.label}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template1;
