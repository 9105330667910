import {
  addPlanPrice,
  blockUserId,
  editPlanPrice,
  failedProductList,
  getCustomer,
  getDashboardUsers,
  getHistoryDataRoute,
  getPlanPrice,
  getSubscriptionGraphUsersApi,
  getSummaryChartsRoute,
  getUserFile,
  historyByUserId,
  historyDetails,
  removePlanPrice,
  unBlockUserId,
  userSubscription,
} from "./apiroutes";

import customAxios from "./interceptor";

export const getUserDataLists = (data) => {
  return async (dispatch) => {
    try {
      const response = await customAxios.post(getCustomer, data);
      //   const userData = response.data.users;
      //   dispatch({ type: "FETCH_USER_SUCCESS", payload: userData });

      // Return data for reference (optional)
      return { status: true, data: response.data };
    } catch (err) {
      //   dispatch({ type: "FETCH_USER_ERROR", payload: err.message });
      return { status: false, message: err.message };
    }
  };
};

export const getHistoryData = (data) => {
  return async (dispatch) => {
    try {
      const response = await customAxios.post(getHistoryDataRoute, data);
      //   const userData = response.data.users;
      //   dispatch({ type: "FETCH_USER_SUCCESS", payload: userData });

      // Return data for reference (optional)
      return { status: true, data: response.data };
    } catch (err) {
      //   dispatch({ type: "FETCH_USER_ERROR", payload: err.message });
      return { status: false, message: err.message };
    }
  };
};

export const blockUserById = (id, blocked, description) => {
  console.log("id", id);
  const userId = {
    user_id: id,
    reason: description,
  };
  const api = blocked ? unBlockUserId : blockUserId;
  return async (dispatch) => {
    try {
      const response = await customAxios.post(api, userId);
      console.log("response", response);
      return { status: true, data: response.data };
    } catch (err) {
      return { status: false, message: err.message };
    }
  };
};

export const exportCSV = (data) => {
  return async (dispatch) => {
    try {
      const response = await customAxios.post(getUserFile, data);
      return { status: true, data: response.data };
    } catch (err) {
      return { status: false, message: err.message };
    }
  };
};

export const getNormalDashboardUsers = async (months = 3) => {
  try {
    const response = await customAxios.get(
      `${getDashboardUsers}?months=${months}`
    );

    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
export const getSummarysCharts = async (months = 3) => {
  try {
    const response = await customAxios.get(`${getSummaryChartsRoute}`);

    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
export const getSubscriptionGraphUsers = async () => {
  try {
    const response = await customAxios.get(`${getSubscriptionGraphUsersApi}`);

    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

//  pricing and plan

export const getPlanPricing = async () => {
  try {
    const response = await customAxios.get(
      `${getPlanPrice}/669a052267ad96dfab59260c`
    );
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const addPlanPricing = async (data) => {
  console.log("data", data);
  const api = `${addPlanPrice}/669a052267ad96dfab59260c`;
  try {
    const response = await customAxios.post(api, data);
    console.log("response", response);
    return { status: true, data: response };
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const removePlanPricing = async (data) => {
  console.log("data", data);
  const api = `${removePlanPrice}/669a052267ad96dfab59260c`;
  try {
    const response = await customAxios.post(api, data);
    console.log("response", response);
    return { status: true, data: response };
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const editPlanPricing = async (data) => {
  console.log("data", data);
  const api = `${editPlanPrice}/669a052267ad96dfab59260c`;
  try {
    const response = await customAxios.patch(api, data);
    console.log("response", response);
    return { status: true, data: response };
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const getUsersSubscription = async (data) => {
  try {
    const response = await customAxios.post(userSubscription, data);
    console.log("response", response);
    if (response.data.success) {
      return { status: true, data: response.data.data };
    } else {
      return {
        status: false,
        data: response.data.data.data,
        message: response.data.message,
      };
    }
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const getHistoryDataByUserId = async (id) => {
  try {
    const api = `${historyByUserId}/${id}`;
    const response = await customAxios.get(api);
    if (response.data.success) {
      return { status: true, data: response.data.data };
    } else {
      return {
        status: false,
        data: response.data.data.data,
        message: response.data.message,
      };
    }
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const historyDetailsByUserID = async (data) => {
  try {
    const response = await customAxios.post(historyDetails, data);
    if (response.data.success) {
      return { status: true, data: response.data.data };
    } else {
      return {
        status: false,
        data: response.data.data.data,
        message: response.data.message,
      };
    }
  } catch (err) {
    return { status: false, message: err.message };
  }
};

export const getFailedProductLists = (data) => {
  return async () => {
    try {
      // Extract the necessary parameters from data
      const {
        limit,
        page,
        nutrition_score_present,
        ingredients_score_present,
        sustainability_score_present,
        filterType,
      } = data;

      // Build the query string using the extracted parameters
      const queryParams = new URLSearchParams({
        limit: limit.toString(),
        page: page.toString(),
        nutrition_score_present: nutrition_score_present.toString(),
        ingredients_score_present: ingredients_score_present.toString(),
        sustainability_score_present: sustainability_score_present.toString(),
        filterType: filterType,
      }).toString();

      // Make the API request using customAxios and the queryParams
      const response = await customAxios.get(
        `${failedProductList}?${queryParams}`
      );
      return { status: true, data: response.data };
    } catch (err) {
      // Return an error message if the API request fails
      return { status: false, message: err.message };
    }
  };
};
