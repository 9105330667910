import React from "react";
import AuthLayout from "../components/layout/AuthLayout";
import LoginForm from "../components/auth/LoginForm";
import withoutAuth from "../routeProtector/withoutAuth";

const Signup = () => {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  );
};

export default withoutAuth(Signup);
