export const EditIcon = ({ className, fill }) => (
  <div className={className}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.0371 11.5751C17.1541 11.6921 17.1541 11.883 17.0371 12L8 20.998H3V15.998L11.998 6.96105C12.115 6.84305 12.3051 6.84305 12.4231 6.96105L17.0371 11.5751ZM20.4099 5.80809L18.1899 3.588C17.4059 2.804 16.1341 2.804 15.3501 3.588L13.4719 5.46605C13.3549 5.58305 13.3549 5.773 13.4719 5.89L18.1079 10.526C18.2249 10.643 18.415 10.643 18.532 10.526L20.4099 8.64806C21.1939 7.86406 21.1939 6.59209 20.4099 5.80809Z"
        fill={fill || "#2D9CDB"}
      />
    </svg>
  </div>
);
