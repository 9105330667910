import { Button, Space } from "antd";
import React from "react";

const CommonButton = ({
  type,
  className,
  onClick,
  children,
  danger,
  leftIcon,
  rightIcon,
  disabled,
  ...props
}) => {
  return (
    <Button
      type={type}
      className={className}
      onClick={onClick}
      danger={danger}
      disabled={disabled}
      {...props}
    >
      <Space>
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </Space>
    </Button>
  );
};

export default CommonButton;
