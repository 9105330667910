// Add a request interceptor
import axios from "axios";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

customAxios.interceptors.request.use(
  function (config) {
    let token = null;
    if (window !== undefined) {
      token = localStorage.getItem("token")
        ? JSON.parse(localStorage?.getItem("user")).token
        : null;
    }
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
customAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log("error", error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    if (error.response) {
      console.log(
        "error.response",
        error.response.data.message === "Invalid bearer token"
      );
      if (
        // error.response.data.message === "Token Expired" ||
        error.response.data.message === "Invalid bearer token"
      ) {
        localStorage.clear();
        sessionStorage.clear();
      }
      return Promise.reject(new Error(error.response.data.message));
    }

    return Promise.reject(error);
  }
);

export default customAxios;
