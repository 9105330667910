import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Customer from "./pages/Customer";
import Dashboard from "./pages/Dashboard";
import ForgotPassword from "./pages/ForgotPassword";
import History from "./pages/History";
import Price from "./pages/Price";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import Subscription from "./pages/Subscription";
import FailedProductsList from "./pages/FailedProductsList";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/customers" element={<Customer />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/history" element={<History />} />
        <Route path="/price" element={<Price />} />
        <Route path="/failed-products-list" element={<FailedProductsList />} />
        <Route path="/login" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
