import { Modal, Input } from "antd";
import React from "react";
import {
  AiFillWarning,
  AiOutlineCheck,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import CommonButton from "../Button/CommonButton";

const ActionsModal = ({
  title,
  type,
  isOpen,
  onCancel,
  onOk,
  footer,
  centered,
  closable,
  maskClosable,
  className,
  cancelBtnClass,
  cancelBtnClick,
  cancelButtonProps,
  saveBtnClass,
  saveBtnClick,
  saveButtonProps,
  savebtnType,
  leftIcon,
  rightIcon,
  icons,
  defaultIconClass,
  mainTitle,
  mainTitleClass,
  description,
  descriptionClass,
  notes = false,
  setNotes,
  enteredNotes,
  errorMessage,
  setError,
  error,
  ...props
}) => {
  let conditionalMainTitle = "";
  let conditionalDescription = "";
  let conditionalIcons;
  let buttonName = "";
  // let saveClass = "";

  if (type === "delete") {
    conditionalMainTitle = "Are you sure?";
    conditionalDescription = "Do you really want to delete these record?";
    conditionalIcons = (
      <AiFillWarning className={defaultIconClass || "deleteIconClass"} />
    );
    buttonName = "Delete";
  } else if (type === "save") {
    conditionalMainTitle = "Save?";
    conditionalDescription = "Save Description";
    conditionalIcons = (
      <AiOutlineCheck className={defaultIconClass || "saveIconClass"} />
    );
    buttonName = "Save";
  } else if (type === "warning") {
    conditionalMainTitle = "Warning";
    conditionalDescription = "WarningDescription";
    conditionalIcons = (
      <AiOutlineExclamationCircle
        className={defaultIconClass || "warningIconClass"}
      />
    );
    buttonName = "warning";
  }

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOk}
      footer={footer}
      centered={centered}
      closable={closable}
      maskClosable={maskClosable}
      className={className}
      {...props}
    >
      <>
        <div>
          <p
            className={
              mainTitleClass ||
              "mainTitledefaultClass items-center font-poppins text-center text-2xl font-bold py-1"
            }
          >
            {mainTitle || conditionalMainTitle}
          </p>
          <p
            className={
              descriptionClass ||
              "descriptiondefaultClass items-center font-poppins text-center font-medium text-gray-400 py-2"
            }
          >
            {description || conditionalDescription}
          </p>
        </div>
        {notes && (
          <div className="flex flex-col gap-4 w-full my-4">
            <p className="w-[50%] font-poppins text-start font-medium text-gray-400 ">
              Reason:
            </p>
            <div className="w-full">
              <Input.TextArea
                type="text"
                rows={4}
                placeholder="Enter your reason here"
                value={enteredNotes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  if (e.target.value) {
                    setError(false);
                  }
                }}
                className="notesInput w-full"
              />
              {error && (
                <p className="text-red-500 text-xs mt-2">{errorMessage}</p>
              )}
            </div>
          </div>
        )}
        <div className="btnContainer">
          <CommonButton
            danger
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            onClick={cancelBtnClick}
            className={
              cancelBtnClass || "cancelBtndefautClass w-full rounded-full my-1"
            }
          >
            {cancelButtonProps || "Cancle"}
          </CommonButton>
          <CommonButton
            type="primary"
            danger={savebtnType}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            onClick={saveBtnClick}
            className={
              saveBtnClass ||
              "saveBtndefautClass w-full bg-red-500 rounded-full my-1 !hover:bg-black"
            }
          >
            {saveButtonProps || buttonName}
          </CommonButton>
        </div>
      </>
    </Modal>
  );
};

export default ActionsModal;
