import { forgotPassword, login, validateToken } from "./apiroutes";
import customAxios from "./interceptor";
import { userAction } from "../store/userReducer";

export function loginAsync(email, password) {
  return async (dispatch) => {
    try {
      if (!email || !password) throw new Error("Please fill all the fields");
      const response = await customAxios.post(login, {
        email,
        password,
        type: "email",
        admin_login: true,
      });
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      dispatch(userAction.setUser(response.data.data));
      return {
        status: true,
        data: response.data,
        message: response.data.message,
      };
    } catch (error) {
      return { status: false, message: error.message };
    }
  };
}

export async function forgotPasswordAsync(email) {
  try {
    if (!email) throw new Error("Please fill all the fields");
    const response = await customAxios.post(forgotPassword, {
      email: email,
      role: "admin",
    });
    return {
      status: true,
      data: response.data,
      message: "Reset Password link sent to email.",
    };
  } catch (error) {
    return { status: false, message: error.message };
  }
}

export async function validateTokenAsync(token) {
  try {
    if (!token) throw new Error("Please fill all the fields");
    const response = await customAxios.post(validateToken, { token });
    return {
      status: true,
      data: response.data,
      message: response.data.message,
    };
  } catch (error) {
    return { status: false, message: error.message };
  }
}
