import React from "react";
import ForgotPasswordForm from "../components/auth/ForgotPasswordForm";
import AuthLayout from "../components/layout/AuthLayout";
import withoutAuth from "../routeProtector/withoutAuth";

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

export default withoutAuth(ForgotPassword);
