import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Space,
  // Typography,
  theme,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import React, { useState } from "react";
import {
  AiFillFund,
  AiOutlineDown,
  AiOutlineLogout,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineDashboard,
} from "react-icons/ai";
import { FaDollarSign } from "react-icons/fa";
import { useSelector } from "react-redux";
import { FaHistory } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AiFillBank } from "react-icons/ai";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";

const CustomLayout = ({ children }) => {
  const user = useSelector((store) => store.user.user);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items = [
    {
      label: (
        <div
          className="flex flex-row"
          onClick={() => {
            localStorage.clear();
            navigate("/login");
          }}
        >
          <AiOutlineLogout className="hover:cursor-pointer" size={25} />
          <p className="pl-3 font-semibold">Logout</p>
        </div>
      ),
      key: "4",
    },
  ];

  let url_str = document.URL;
  let url = new URL(url_str);

  return (
    <Layout
      hasSider
      style={{
        height: "100vh",
      }}
    >
      <Sider trigger={null} collapsible collapsed={collapsed}>
        {/* if add any name  */}
        {/* <Typography className="text-white text-xl text-center my-4 font-semibold">
            {!collapsed ? "Dashboard" : "D"}
          </Typography> */}
        {/* if add any logo  */}

        <div className="my-4 flex items-center justify-center">
          {" "}
          <Avatar
            src="https://robohash.org/hicveldicta.png"
            className="w-[30px] h-[30px] rounded-full shadow-lg object-cover bg-[#f5f5f5]"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultValue={url.pathname}
          selectedKeys={[url.pathname]}
          items={[
            {
              key: "/",
              icon: <AiOutlineDashboard />,
              onClick: () => {
                navigate("/");
              },
              label: "Dashboard",
            },
            {
              key: "/customers",
              icon: <AiFillFund />,
              onClick: () => {
                navigate("/customers");
              },
              label: "Customers",
            },
            {
              key: "/history",
              icon: <FaHistory />,
              onClick: () => {
                navigate("/history");
              },
              label: "User's History",
            },
            {
              key: "/price",
              icon: <AiFillBank />,
              onClick: () => {
                navigate("/price");
              },
              label: "Plan Price",
            },
            {
              key: "/subscription",
              icon: <FaDollarSign />,
              onClick: () => {
                navigate("/subscription");
              },
              label: "Subscription",
            },
            {
              key: "/failed-products-list",
              icon: <MdOutlineProductionQuantityLimits />,
              onClick: () => {
                navigate("/failed-products-list");
              },
              label: "Failed Products",
            },
            // {
            //   key: "/login",
            //   icon: <AiOutlineLogout />,
            //   onClick: () => {
            //     localStorage.clear();
            //     navigate("/login");
            //   },
            //   label: "Logout",
            // },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{ padding: 0, background: colorBgContainer }}
          className="flex justify-between items-center"
        >
          <Button
            type="text"
            icon={collapsed ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <div className="mr-5">
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              className="flex"
            >
              <a href="/" onClick={(e) => e.preventDefault()}>
                <Space size="small">
                  <Avatar
                    src={"https://robohash.org/hicveldicta.png"}
                    className="w-[30px] h-[30px] rounded-full shadow-lg object-cover bg-[#483ea3]"
                  />

                  <div className="capitalize font-medium font-poppins leading-6	text-xl text-[#000000]">
                    {user?.username}
                  </div>
                  <AiOutlineDown />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "85vh",
            background: colorBgContainer,
            overflowY: "scroll",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
