import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFailedProductLists } from "../apis/user";
import CommonTable from "../components/common/CommonTable";
import CutOffText from "../components/common/CutOffText";
import { useLoader } from "../components/common/Loader/LoaderProvider";
import CustomLayout from "../components/layout/CustomLayout";
import withAuth from "../routeProtector/withAuth";
import { failedProductTime } from "../utils/constant";
import { Checkbox, Select } from "antd";

const FailedProductsList = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [date, setDate] = useState("all");

  // New checkbox states
  const [nutritionScorePresent, setNutritionScorePresent] = useState(true);
  const [ingredientsScorePresent, setIngredientsScorePresent] = useState(true);
  const [sustainabilityScorePresent, setSustainabilityScorePresent] =
    useState(true);

  const loaderContext = useLoader();
  const { showLoader, hideLoader } = loaderContext;

  const fetchFailedProducts = async () => {
    const data = {
      limit,
      page: currentPage,
      filterType: date,
      nutrition_score_present: nutritionScorePresent, // From checkbox state
      ingredients_score_present: ingredientsScorePresent, // From checkbox state
      sustainability_score_present: sustainabilityScorePresent, // From checkbox state
    };
    showLoader();
    dispatch(getFailedProductLists(data))
      .then((res) => {
        if (res.status) {
          setUserData(res.data.data.data);
          setLimit(res?.data?.data?.limit);
          setCurrentPage(res?.data?.data?.page);
          setTotalItems(res.data.data.totalItems);
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .finally(() => {
        hideLoader();
      });
  };

  useEffect(() => {
    fetchFailedProducts();
  }, [
    limit,
    currentPage,
    date,
    nutritionScorePresent,
    ingredientsScorePresent,
    sustainabilityScorePresent,
  ]);

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      sorter: (a, b) =>
        (a.user_name &&
          b.user_name &&
          a.user_name.localeCompare(b.user_name)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      sorter: (a, b) =>
        (a.user_email &&
          b.user_email &&
          a.user_email.localeCompare(b.user_email)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Product Id",
      dataIndex: "product_id",
      key: "product_id",
      sorter: (a, b) =>
        (a.product_id &&
          b.product_id &&
          a.product_id.localeCompare(b.product_id)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) =>
        (a.product_name &&
          b.product_name &&
          a.product_name.localeCompare(b.product_name)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: (a, b) =>
        (a.brand_name &&
          b.brand_name &&
          a.brand_name.localeCompare(b.brand_name)) ||
        0,
      render: (text) => <CutOffText maxLength={20} value={text} />,
      ellipsis: true,
    },
    {
      title: "Barcode",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a, b) =>
        (a.barcode && b.barcode && a.barcode.localeCompare(b.barcode)) || 0,
      ellipsis: true,
      render: (text) => (
        <>
          {text.trim() !== "" ? (
            <CutOffText maxLength={20} value={text} />
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Nutrition",
      dataIndex: "nutrition_present",
      key: "nutrition_present",
      sorter: (a, b) =>
        (a.nutrition_present &&
          b.nutrition_present &&
          a.nutrition_present.localeCompare(b.nutrition_present)) ||
        0,
      ellipsis: true,
      render: (text) => <>{text === false ? "False" : "True"}</>,
    },
    {
      title: "Ingredient",
      dataIndex: "ingredients_present",
      key: "ingredients_present",
      sorter: (a, b) =>
        (a.ingredients_present &&
          b.ingredients_present &&
          a.ingredients_present.localeCompare(b.ingredients_present)) ||
        0,
      ellipsis: true,
      render: (text) => <>{text === false ? "False" : "True"}</>,
    },
    {
      title: "Sustainability",
      dataIndex: "sustainability_present",
      key: "sustainability_present",
      sorter: (a, b) =>
        (a.sustainability_present &&
          b.sustainability_present &&
          a.sustainability_present.localeCompare(b.sustainability_present)) ||
        0,
      ellipsis: true,
      render: (text) => <>{text === false ? "False" : "True"}</>,
    },
    {
      title: "Overall Status",
      dataIndex: "overall_status",
      key: "overall_status",
      sorter: (a, b) =>
        (a.overall_status &&
          b.overall_status &&
          a.overall_status.localeCompare(b.overall_status)) ||
        0,
      ellipsis: true,
      render: (text) => <div className="capitalize">{text}</div>,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setLimit(size);
  };

  return (
    <CustomLayout>
      {/* Checkboxes for filter options */}
      <div className="mb-4">
        <Select
          defaultValue={date}
          value={date}
          onChange={(value) => setDate(value)}
          placeholder="Select Date"
          options={failedProductTime.map((option) => ({
            value: option.value,
            label: option.label,
          }))}
          className="w-[200px] mr-[20px]"
        />
        <label className="mr-4">
          <Checkbox
            checked={nutritionScorePresent}
            onChange={(e) => setNutritionScorePresent(e.target.checked)}
          >
            {" "}
            Nutrition Score Absent
          </Checkbox>
        </label>
        <label className="mr-4">
          <Checkbox
            checked={ingredientsScorePresent}
            onChange={(e) => setIngredientsScorePresent(e.target.checked)}
          >
            {" "}
            Ingredients Score Absent
          </Checkbox>
        </label>
        <label>
          <Checkbox
            checked={sustainabilityScorePresent}
            onChange={(e) => setSustainabilityScorePresent(e.target.checked)}
          >
            {" "}
            Sustainability Score Absent
          </Checkbox>
        </label>
      </div>

      <CommonTable
        apiData={userData && userData}
        columns={columns}
        currentPage={currentPage}
        itemsPerPage={limit}
        onPageChange={handlePageChange}
        onShowSizeChange={handleSizeChange}
        search={false}
        totalItems={totalItems}
        filter={true}
        showCSV={false}
      />
    </CustomLayout>
  );
};

export default withAuth(FailedProductsList);
