import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto"; //*auto registery of Chart is required to use Graph elements.

const QuarterUsersGraph = ({
  chartData,
  options,
  height,
  graphType,
  className,
}) => {
  const doughnutOptions = {
    ...options,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          padding: 10,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
  };
  return (
    <>
      {graphType === "bar" && (
        <Bar
          data={chartData}
          height={height}
          options={options}
          className={className}
        />
      )}
      {graphType === "circle" && (
        <Doughnut
          data={chartData}
          height={height}
          className={className}
          options={doughnutOptions}
        />
      )}
    </>
  );
};

export default QuarterUsersGraph;
