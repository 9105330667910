import { Divider, Input, Typography } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { loginAsync } from "../../apis/authentication";
import { loginValidationSchema } from "../../utils/validation";
import { useLoader } from "../common/Loader/LoaderProvider";

const LoginForm = () => {
  const passwordRef = useRef(null);
  const btnRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loaderContext = useLoader();
  const { showLoader, hideLoader } = loaderContext;
  const loginInitialValues = {
    email: "",
    password: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleReset,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      showLoader();
      if (!values.email || !values.password) {
        toast.error("Please fill all the fields");
      } else {
        dispatch(loginAsync(values.email, values.password))
          .then((data) => {
            hideLoader();
            if (data.status) {
              toast.success(data.message);
              handleReset();
              navigate("/");
            } else {
              toast.error(data.message);
            }
          })
          .catch((err) => {
            hideLoader();
            toast.error(err.message);
          });
      }
      hideLoader();
    },
  });

  useEffect(() => {
    hideLoader();
    // eslint-disable-next-line
  }, []);
  const handleNextRef = (e) => {
    if (e.key === "Enter") {
      if (e.target.name === "email") passwordRef.current.focus();
      if (e.target.name === "password") btnRef.current.focus();
    }
  };

  return (
    <div className="flex-col w-full md:w-[70%] xl:w-7/12 gap-3 p-5">
      <ToastContainer limit={1} autoClose={1000} />
      <Typography className="text-4xl font-bold text-center mb-8">
        Login
      </Typography>

      {/* form */}
      <div className="flex gap-1 flex-col items-center">
        <form className="w-full">
          <div className="w-full">
            <Typography className="text-md font-semibold mb-2">
              Email
            </Typography>
            <Input
              type="text"
              name="email"
              placeholder="Email"
              autoComplete="off"
              value={values.email}
              status={errors.email && touched.email && "error"}
              onChange={handleChange}
              onBlur={handleBlur}
              className="border-2 border-gray-300 p-3 text-lg"
              onKeyDown={handleNextRef}
            />
            {errors.email && touched.email && (
              <p className="text-red-400 text-xs font-semibold">
                {errors.email}
              </p>
            )}
          </div>

          <div className="w-full mt-2">
            <Typography className="text-md font-semibold mb-2">
              Password
            </Typography>
            <Input.Password
              ref={passwordRef}
              type="password"
              name="password"
              autoComplete="off"
              placeholder="Password"
              value={values.password}
              status={errors.password && touched.password && "error"}
              onChange={handleChange}
              onBlur={handleBlur}
              className="border-2 border-gray-300 p-3 text-lg"
              onKeyDown={handleNextRef}
            />
            {errors.password && touched.password && (
              <p className="text-red-400 text-xs font-semibold">
                {errors.password}
              </p>
            )}
          </div>
        </form>

        <button
          disabled={Object.keys(errors).length > 0}
          ref={btnRef}
          type="submit"
          onClick={handleSubmit}
          className="mt-2 bg-blue-500 disabled:bg-blue-200 hover:bg-blue-700 shadow-xl text-white p-3 text-lg rounded-md w-full"
        >
          Login
        </button>
      </div>

      {/* divder */}
      <div className="flex-col">
        <Divider className="border-[1px] bg-gray-400/40" />
        <Typography
          className="text-blue-500 hover:cursor-pointer hover:text-blue-700 text-center"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot password
        </Typography>
      </div>
    </div>
  );
};

export default LoginForm;
