import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withoutAuth = (Component) => {
  // every page must be binded with withoutAuth in order to make it public.
  // this component validate user if user is authenticated then it will navigate to dashboard page
  // else it will redirect to desire page

  const Auth = (props) => {
    const router = useNavigate();
    // const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const token = localStorage.getItem("token");
    const isAuthenticated = token ? true : false;

    useEffect(() => {
      // this code snipet will check is user authenticated or not
      if (isAuthenticated) {
        router("/");
      }
      // eslint-disable-next-line
    }, []);

    // return desire component if user is unauthenticated
    return <Component {...props} />;
  };
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }
  return Auth;
};

export default withoutAuth;
