export const UpIcon = ({ fill, className, onClick, width, height }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        width={width || "20"}
        height={height || "11"}
        viewBox="0 0 20 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9.875L10 1.125L1 9.875"
          stroke={fill || "black"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
