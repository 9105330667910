import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .trim(),
  password: Yup.string().required("Password is required").trim(),
});

export const emailSendValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .trim(),
});

export const passwordValidationSchema = Yup.object({
  otp: Yup.string().required("Otp is required").trim(),
  password: Yup.string().required("Password is required").trim(),
  repassword: Yup.string().required("Re-Password is required").trim(),
});


export const planPricingValidationSchema = Yup.object().shape({
  country: Yup.string()
    .required("Country is required")
    .test(
      "no-spaces",
      "Country should not contain only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  currency: Yup.string().required("Currency is required"),
  monthly_price: Yup.string()
    .required("Monthly Price is required")
    .matches(/^[0-9]+$/, "Monthly Price must contain only numbers")
    .test(
      "no-spaces",
      "Monthly Price should not contain only spaces",
      (value) => !/^\s+$/.test(value)
    ),
  yearly_price: Yup.string()
    .required("Yearly Price is required")
    .matches(/^[0-9]+$/, "Yearly Price must contain only numbers")
    .test(
      "no-spaces",
      "Yearly Price should not contain only spaces",
      (value) => !/^\s+$/.test(value)
    ),
});