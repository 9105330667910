import React from "react";
import { camelCase } from "../../utils/constant";
import { BackIcon } from "../Icons/BackIcon";
import { Avatar, Badge, Space } from "antd";

const ViewUserDetails = ({ user, setShowUserDetails }) => {
  const myPreferences = user?.preferences;
  const profile_pic = !user?.profile_pic
    ? "https://robohash.org/hicveldicta.png"
    : user?.profile_pic;

  const showPreferences =
    myPreferences && Object.keys(myPreferences).length > 0;
  console.log("myPreferences", myPreferences);
  return (
    <>
      <div className="Container">
        <div>
          <div className="titlediv flex justify-between pt-[10px] pb-[30px]">
            <Space>
              <BackIcon
                onClick={() => setShowUserDetails(false)}
                className="cursor-pointer hover:text-[blue]"
              />

              <div className="flex justify-between text-gray-700 font-baijamjuree text-[22px] font-semibold leading-6">
                Customer Profile
              </div>
            </Space>
          </div>
          <div
            className="drop-shadow-custom w-full md:pl-0"
            bordered={false}
            size="default"
          >
            <div className="flex flex-col md:flex-row">
              <div className="mb-4 md:mb-0 md:mr-6">
                <Avatar shape="square" size={100} src={profile_pic} />
              </div>
              <div className="w-full">
                <div className="flex flex-row gap-3 items-center">
                  <div className="text-xl md:text-2xl text-[#16A2D5] font-bold mb-2">
                    {!user?.name ? "-" : camelCase(user?.name)}
                  </div>
                  <Space
                    className={`inlineFlex items-center px-3 py-1 rounded-[20px] border-none ${
                      user?.blocked == false ? "bg-[#C3F4E5]" : "bg-[#E9EBED]"
                    }`}
                  >
                    <span className="text-base font-medium hover:text-[#333] capitalize">
                      {user?.blocked == true ? "inActive" : "Active"}
                    </span>
                  </Space>
                </div>
                <div className="detailsGrid grid grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Email
                      </div>
                      <div className=" font-medium text-gray-500 text-base">
                        {!user?.email ? "-" : user?.email}
                      </div>
                    </div>
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Gender
                      </div>
                      <div className="flex  font-medium text-gray-500 text-base">
                        {!user?.gender ? "-" : user?.gender}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Phone Number
                      </div>
                      <div className=" font-medium text-gray-500 text-base">
                        {!user?.phone_number ? "-" : user?.phone_number}
                      </div>
                    </div>
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Date of Birth
                      </div>
                      <div className=" font-medium text-gray-500 text-base">
                        {!user?.dob ? "-" : user?.dob}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Height
                      </div>
                      <div className=" font-medium text-gray-500 text-base">
                        {!user?.height ? "-" : user?.height}
                      </div>
                    </div>
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Ethnicity
                      </div>
                      <div className=" font-medium text-gray-500 text-base">
                        {!user?.ethnicity ? "-" : user?.ethnicity}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Weight
                      </div>
                      <div className=" font-medium text-gray-500 text-base">
                        {!user?.weight ? "-" : user?.weight}
                      </div>
                    </div>
                    <div className="py-[15px]">
                      <div className=" font-bold text-[#333333] text-base">
                        Health Condition
                      </div>
                      <div className=" font-medium text-gray-500 text-base">
                        {!user?.health_condition ? "-" : user?.health_condition}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPreferences && (
        <div className="Container mt-[15px]">
          <div>
            <div className="titlediv flex justify-between pt-[10px] pb-[30px]">
              <div className="flex justify-between text-gray-700 font-baijamjuree text-[22px] font-semibold leading-6">
                My Preferences
              </div>
            </div>
            <div
              className="drop-shadow-custom w-full md:pl-0"
              bordered={false}
              size="default"
            >
              {myPreferences &&
                Object.entries(myPreferences)
                  .filter(([_, options]) => options.length > 0) // Filter out categories with empty arrays
                  .map(([category, options], index) => (
                    <div key={index}>
                      <span className="text-[14px] font-semibold text-[#333] max-w-[700px] leading-[17.64px] not-italic capitalize pb-1 border-b border-[blue]">
                        {category.replace(/_/g, " ")}
                      </span>
                      <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-[10px] gap-2 pl-[15px]">
                        {options.map((option, index) => (
                          <span
                            key={index}
                            className="font-normal text-[13px] md:text-[14px] leading-[27.49px] text-[#1D1D1D] capitalize !min-w-[48%] max-w-fit"
                          >
                            <Badge
                              color={"blue"}
                              text={option.label?.replace(/_/g, " ")}
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewUserDetails;
