import React, { createContext, useContext } from "react";
import { notification as antdNotification } from "antd";

const NotificationContext = createContext(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  return context;
};

export const NotificationProvider = ({ children }) => {
  const handleNotifications = (
    type = "success" | "info" | "warning" | "error",
    message,
    description,
    duration
  ) => {
    antdNotification[type]({
      message: (
        <span style={{ fontWeight: "bold", marginRight: "5px" }}>
          {message}
        </span>
      ),
      key: message,
      description,
      duration,
    });
  };
  return (
    <NotificationContext.Provider value={{ handleNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
