import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

// const rootReducer = combineReducers({
//   reducer: {
//     user: userReducer,
//   },
// });

const rootReducer = combineReducers({ user: userReducer });

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
let persistor = persistStore(store);
export { store, persistor };
