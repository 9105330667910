import React from "react";
import { Pagination } from "antd";

const CommonPagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  onShowSizeChange,
  pageSizeOptions,
  className,
  showSizeChanger = true,
  showQuickJumper = totalItems > itemsPerPage,
}) => {
  return (
    <Pagination
      className={className}
      current={currentPage}
      total={totalItems}
      pageSize={itemsPerPage}
      onChange={onPageChange}
      onShowSizeChange={onShowSizeChange}
      showSizeChanger={showSizeChanger}
      pageSizeOptions={pageSizeOptions}
      // hideOnSinglePage={totalItems <= itemsPerPage}
      showQuickJumper={showQuickJumper}
    />
  );
};

export default CommonPagination;
