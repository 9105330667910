import { Typography } from "antd";
import GradientRange from "./GradientRange";

const Template2 = ({ values }) => {
  console.log("values", values);
  const dietaryFiberScore = values[Object.keys(values)[0]];
  console.log("dietaryFiberScore", dietaryFiberScore?.more_information);
  const moreInformationArray =
    (dietaryFiberScore?.itag_template === "template-1" ||
      dietaryFiberScore?.itag_template === "template-2") &&
    dietaryFiberScore?.more_information &&
    dietaryFiberScore?.more_information
      ?.split(/[,|]/)
      ?.map((info) => info?.trim());
  console.log("moreInformationArray", moreInformationArray);

  const references = dietaryFiberScore?.reference_url
    ?.split(/[,|]/)
    ?.map((ref) => {
      const colonIndex = ref.indexOf(":");
      if (colonIndex !== -1) {
        const label = ref?.substring(0, colonIndex)?.trim();
        const url = ref?.substring(colonIndex + 1)?.trim();
        return { label, url };
      } else {
        return { label: "", url: ref?.trim() };
      }
    });


  const moreInformationItems =
    dietaryFiberScore?.itag_template === "template-4" &&
    dietaryFiberScore?.more_information.split(/[,|]/);

  return (
    <div className="h-auto w-[249.63px] flex flex-col justify-between  px-[13px] py-[10px] rounded-[13px] border">
      {(dietaryFiberScore?.itag_template === "template-1" ||
        dietaryFiberScore?.itag_template === "template-2") && (
        <>
          <div className="capitalize flex justify-center items-start text-[#2F2F2F] font-[700] text-[18px] leading-[15.78px] ">
            {Object.keys(values)[0]
              ? Object.keys(values)[0]
                  ?.replace(/_/g, " ")
                  ?.replace(/\b\w/g, (char) => char?.toUpperCase())
              : "H2O Score Range"}
          </div>
          <GradientRange
            currentValue={dietaryFiberScore?.value}
            startValue={dietaryFiberScore?.more_info_visual?.start_data}
            startBetterValue={
              dietaryFiberScore?.more_info_visual?.start_better_range
            }
            endBetterValue={
              dietaryFiberScore?.more_info_visual?.end_better_range
            }
            endValue={dietaryFiberScore?.more_info_visual?.end_data}
            unit={dietaryFiberScore?.unit}
          />

          {moreInformationArray && moreInformationArray?.length && (
            <div className="">
              <ul className="list-disc ml-4 mb-3">
                {moreInformationArray?.map((info, index) => (
                  <li
                    key={index}
                    className="font-normal text-[14px] leading-[15.78px]"
                  >
                    {info}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
      {(dietaryFiberScore?.itag_template === "template-3" ||
        dietaryFiberScore?.itag_template === "template-4") && (
        <div className="flex flex-col gap-[15px] justify-center items-center my-2">
          <span className="font-[700] text-[26px] leading-[32.76px]">Info</span>
        </div>
      )}

      {dietaryFiberScore?.itag_template === "template-3" &&
        dietaryFiberScore?.more_information &&
        dietaryFiberScore?.more_information?.length > 0 && (
          <div className="flex justify-center items-center">
            <ul className="list-disc ml-4 mb-3">
              {dietaryFiberScore?.more_information
                ?.filter((info) => info && info?.trim())
                ?.map((info, index) => (
                  <li
                    key={index}
                    className="font-normal text-[14px] leading-[15.78px]"
                  >
                    {info}
                  </li>
                ))}
            </ul>
          </div>
        )}

      {dietaryFiberScore?.itag_template === "template-4" &&
        dietaryFiberScore?.more_information && (
          <div className="px-3 flex justify-center items-center w-full mb-4">
            <ul className="list-disc">
              {moreInformationItems?.map((item, index) => (
                <li
                  key={index}
                  className="font-normal text-[14px] leading-[15.78px]"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        )}
      {dietaryFiberScore?.reference_url && (
        <div className="p-[1px] bg-slate-200 mb-4" />
      )}
      <div className="flex flex-row items-start w-full">
        <div>
          <Typography.Text className="text-[#1D1D1D] font-[700] text-[14px] leading-[16.8px] capitalize">
            Ref:
          </Typography.Text>
        </div>
        <div className="text-start">
          {dietaryFiberScore?.reference_url &&
            references?.map((ref, index) => (
              <div key={index} className={`ml-[4px]`}>
                <div className="font-[500] text-[14px] leading-[15.78px] mt-1">
                  <a
                    href={ref?.url}
                    target="popup"
                    rel="noopener noreferrer"
                    onClick={() => {
                      window.open(
                        `${ref?.url}`,
                        "popup",
                        "width=600,height=600,margin=auto,scrollbars=yes"
                      );
                      return false;
                    }}
                    className="text-blue-400 font-[500] text-[14px] leading-[18.41px] capitalize"
                  >
                    {ref.label}
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Template2;
