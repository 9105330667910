import React from "react";

import { ToastContainer } from "react-toastify";
import source1 from "../../assets/images/Frame.png";
import source2 from "../../assets/images/scoriFiLogo.png";
const AuthLayout = ({ children }) => {
  return (
    <section className="flex relative overflow-hidden">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        draggable
        pauseOnHover
        limit={1}
        theme="light"
      />
      {/* left side */}
      <section className="h-screen w-1/2 relative hidden lg:block ">
        <div className=" pl-[15px] pt-[18px]">
          <img
            alt="logo"
            className=""
            src={source2}
            style={{
              backgroundImage: `url(${source2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </div>
        <div className="h-full w-full flex justify-center items-center inset-0">
          <img
            alt="logo"
            className="mb-[150px]"
            src={source1}
            style={{
              backgroundImage: `url(${source1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </div>
      </section>

      {/* right side */}
      <section className="h-screen w-full lg:w-1/2 flex justify-center items-center">
        {children}
      </section>
    </section>
  );
};

export default AuthLayout;
