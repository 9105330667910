import { Card, Divider, Result, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  getNormalDashboardUsers,
  getSubscriptionGraphUsers,
  getSummarysCharts,
} from "../apis/user";
import { useLoader } from "../components/common/Loader/LoaderProvider";
import QuarterUsersGraph from "../components/common/QuarterUsersGraph";
import CustomLayout from "../components/layout/CustomLayout";
import withAuth from "../routeProtector/withAuth";
import { graphOptions } from "../utils/constant";

const Dashboard = () => {
  const loaderContext = useLoader();
  const { showLoader, hideLoader, loading } = loaderContext;
  const [totalUserData, setTotalUserData] = useState([]);
  const [totalSearchData, setTotalSearchData] = useState([]);
  const [totalSubscriptionData, setTotalSubscriptionData] = useState([]);
  const [months, setMonths] = useState(3);
  const [totalScoredProducts, setTotalScoredProducts] = useState(0);
  const [subscriptionUserData, setSubscriptionUserData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [scoredProductStatus, setScoredProductStatus] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  });
  const [chartBarData, setChartBarData] = useState({
    data: { labels: [], datasets: [] },
    options: {},
  });

  useEffect(() => {
    showLoader();
    getNormalDashboardUsers(months)
      .then((res) => {
        if (res.status) {
          setTotalUserData(res?.data?.data?.total_users);
          setTotalSearchData(res?.data?.data?.total_searches);
          setTotalSubscriptionData(res?.data?.data?.total_subscribed);
          setChartBarData({
            data: {
              labels: res?.data?.data?.total_users_by_months?.monthYear?.map(
                (item) => item
              ),
              datasets: [
                {
                  label: "Users Registered",
                  maxBarThickness: 45,
                  data: res?.data?.data?.total_users_by_months?.usersCount?.map(
                    (item) => item
                  ),
                },
              ],
            },
            options: {
              scales: {
                y: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    precision: 0,
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                },
              },
            },
          });
          hideLoader();
        } else {
          hideLoader();
        }
      })
      .finally(() => {
        hideLoader();
      });

    // eslint-disable-next-line
  }, [months]);

  useEffect(() => {
    showLoader();
    getSubscriptionGraphUsers()
      .then((res) => {
        if (!res?.status) {
          hideLoader();
          return;
        }
        setSubscriptionUserData({
          data: {
            labels: ["Premium", "Trial", "Free"],
            datasets: [
              {
                data: res?.data?.data?.data?.map((item) => item),

                backgroundColor: [
                  "rgb(255, 99, 132)",
                  "rgb(54, 162, 235)",
                  "rgb(255, 205, 86)",
                ],
                radius: "100%",
                // hoverOffset: 4,
              },
            ],
          },
        });
        hideLoader();
      })
      .finally(() => {
        hideLoader();
      });
  }, []);
  const scoredFailedProductData = {};
  useEffect(() => {
    showLoader();
    getSummarysCharts()
      .then((res) => {
        if (!res?.status) {
          hideLoader();
          return;
        }
        console.log("res", res);
        const { scored_products, failed_products, total_products } =
          res?.data?.data;
        console.log(
          "scored_products, failed_products",
          scored_products,
          failed_products,
          total_products
        );
        setTotalScoredProducts(total_products);
        setScoredProductStatus({
          data: {
            labels: ["Scored Products", "Failed Products"],
            datasets: [
              {
                data: [scored_products, failed_products],
                backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
                radius: "100%",
              },
            ],
          },
        });
        hideLoader();
      })
      .finally(() => {
        hideLoader();
      });
  }, []);
  const handlePreviousMonths = (month) => {
    setMonths(month);
  };
  console.log("scoredProductStatus", scoredProductStatus);
  return (
    <CustomLayout>
      {!loading && totalUserData > 0 ? (
        <div className="flex flex-col gap-7">
          <div className="flex flex-row gap-4">
            <Card bordered={false} className="border !w-full h-full">
              <div id="parent-container" className="flex flex-col gap-5">
                <div className="font-bold flex flex-row justify-between ">
                  <div className="border-b-2 border-blue-300" id="count-1">
                    Total Users
                  </div>
                </div>
                <div
                  className="font-bold flex flex-col justify-center items-center gap-2"
                  id="counts-col"
                >
                  <div className="" id="title-2">
                    User Statistics
                  </div>
                  <div
                    className="font-semibold text-[28px] leading-5 text-blue-300"
                    id="count-2"
                  >
                    {totalUserData}
                  </div>
                </div>
              </div>
            </Card>
            <Card bordered={false} className="border  !w-full h-full">
              <div id="parent-container" className="flex flex-col gap-5">
                <div className="font-bold flex flex-row justify-between ">
                  <div className="border-b-2 border-blue-300" id="count-1">
                    Total Searches
                  </div>
                </div>
                <div
                  className="font-bold flex flex-col justify-center items-center gap-2"
                  id="counts-col"
                >
                  <div className="" id="title-2">
                    Searches Statistics
                  </div>
                  <div
                    className="font-semibold text-[28px] leading-5 text-blue-300"
                    id="count-2"
                  >
                    {totalSearchData}
                  </div>
                </div>
              </div>
            </Card>
            <Card bordered={false} className="border  !w-full h-full">
              <div id="parent-container" className="flex flex-col gap-5">
                <div className="font-bold flex flex-row justify-between ">
                  <div className="border-b-2 border-blue-300" id="count-1">
                    Total subscriptions
                  </div>
                </div>
                <div
                  className="font-bold flex flex-col justify-center items-center gap-2"
                  id="counts-col"
                >
                  <div className="" id="title-2">
                    Premium Users
                  </div>
                  <div
                    className="font-semibold text-[28px] leading-5 text-blue-300 "
                    id="count-2"
                  >
                    {totalSubscriptionData}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="flex flex-col gap-4">
            <Card
              title={<div className="text-lg">User Statistics</div>}
              bordered={false}
              className="border w-full !h-fit"
            >
              <Select
                defaultValue={months}
                value={months}
                style={{ width: 150 }}
                onChange={handlePreviousMonths}
                options={graphOptions}
              />
              <div className="grid grid-cols-2 gap-3 w-full max-w-full min-w-full">
                {chartBarData?.data?.datasets?.length > 0 &&
                chartBarData?.data?.labels?.length > 0 ? (
                  <div className="!w-full !h-full">
                    {" "}
                    {/* Adjust width as needed */}
                    <QuarterUsersGraph
                      graphType={"bar"}
                      chartData={chartBarData?.data}
                      options={chartBarData?.options}
                      className="!h-full"
                    />
                  </div>
                ) : (
                  <div className="w-1/2">
                    <h1>No Data Found</h1>
                  </div>
                )}
                {subscriptionUserData?.data ? (
                  <div className="!w-full !h-full">
                    {" "}
                    {/* Adjust width as needed */}
                    <QuarterUsersGraph
                      graphType={"circle"}
                      chartData={subscriptionUserData?.data}
                      className="!m-0 !p-0"
                    />
                  </div>
                ) : (
                  <div className="w-1/2">
                    <h1>No Data Found</h1>
                  </div>
                )}
              </div>
              <Divider className="my-[80px]" />
              {scoredProductStatus?.data ? (
                <div className="!w-[500px] flex flex-col items-center">
                  <div className="flex flex-row gap-1 items-center justify-center mb-3">
                    <h2 className="text-xl font-semibold text-blue-600">
                      Total Products :
                    </h2>
                    <p className="text-xl font-bold text-gray-800">
                      {totalScoredProducts}
                    </p>
                  </div>
                  <div className="!w-full !h-[300px]">
                    <QuarterUsersGraph
                      graphType={"circle"}
                      chartData={scoredProductStatus?.data}
                      className="!m-0 !p-0"
                    />
                  </div>
                </div>
              ) : (
                <div className="w-1/2">
                  <h1>No Data Found</h1>
                </div>
              )}
            </Card>
          </div>
        </div>
      ) : (
        <Result status="404" title="No Data Found" />
      )}
    </CustomLayout>
  );
};
export default withAuth(Dashboard);
