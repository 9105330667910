import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { LoaderProvider } from "./components/common/Loader/LoaderProvider";
import "./index.css";
import { persistor, store } from "./store/store";
import { NotificationProvider } from "./components/common/NotificationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoaderProvider>
        <NotificationProvider>
          <App />
          </NotificationProvider>
        </LoaderProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
